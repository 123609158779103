import { ActionType, createAction } from 'typesafe-actions';
import { Result } from 'lib/types/Result';
import { CampaignResponse } from 'lib/api/apiTypes/CampaignResponse';
import { CertificateResponse } from 'lib/api/apiTypes/CertificateResponse';

export const CampaignActions = {
  fetchResult: createAction('campaign/FETCH_RESULT')<Result<CampaignResponse>>(),
  languageSelected: createAction('campaign/LANGUAGE_SELECTED')<string>(),
  languageChangeSuccess: createAction('campaign/LANGUAGE_CHANGE_SUCCESS')(),
  certificateFetchResult: createAction('campaign/CERTIFICATE_FETCH_RESULT')<Result<CertificateResponse>>(),
  isReturningLearner: createAction('initialization/IS_RETURNING_LEARNER')<boolean>(),
  spinToWinCampaignCompleted: createAction('campaign/SPIN_TO_WIN_CAMPAIGN_COMPLETED')(),
};

export type CampaignActions = ActionType<typeof CampaignActions>;
