import styled, { keyframes } from 'styled-components';
import { transparentize } from 'polished';
const SpinnerKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
export const Spinner = styled.span.attrs({ dir: 'ltr' })<{ size: string; color?: string }>`
  display: inline-block;
  font-size: 10px;
  text-indent: -9999em;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border-radius: 50%;
  background: ${(props) => transparentize(0.5, props.color ?? props.theme.colors.highlight.color)};
  /*! @noflip */
  background: linear-gradient(
    to right,
    ${(props) => transparentize(0.5, props.color ?? props.theme.colors.highlight.color)} 10%,
    rgba(255, 255, 255, 0) 42%
  );
  position: relative;
  /*! @noflip */
  animation: ${SpinnerKeyframes} 0.8s infinite linear;
  transform: translateZ(0);
  mask-image: radial-gradient(circle farthest-side at center, transparent 59%, white 60%);
  &:before {
    width: 50%;
    height: 50%;
    background: ${(props) => transparentize(0.5, props.color ?? props.theme.colors.highlight.color)};
    /*! @noflip */
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    /*! @noflip */
    left: 0;
    content: '';
  }
`;
