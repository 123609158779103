import { CampaignResponse, isOngoingCampaign, isPassRateRequiredCampaign } from 'lib/api/apiTypes/CampaignResponse';
import { SCORMFuncs } from 'lib/SCORM/SCORMFuncs';
import { FINAL_ASSESSMENT_MODULE_ID } from './index';
import { ErrorWithData } from 'lib/errors/ErrorWithData';
import { trackException } from 'tracking';

export function getScormCompletionSignallingHandler(
  scormFuncs: SCORMFuncs | undefined,
): (campaignResponse: CampaignResponse) => Promise<void> {
  if (scormFuncs) {
    return async (campaignResponse: CampaignResponse) => {
      if (
        isOngoingCampaign(campaignResponse) &&
        campaignResponse.completedModules.includes(FINAL_ASSESSMENT_MODULE_ID)
      ) {
        // these should be signalled from the training content,
        // but just in case they were not, we try to set them here.
        // If they have already been set and completion has already been signalled
        // then these messages should not have any effect as the SCORM
        // implementation should not allow for further changes
        // once completion has been signalled.
        if (isPassRateRequiredCampaign(campaignResponse)) {
          if (campaignResponse.failedAllAttempts || !campaignResponse.failedFinalAssessment) {
            try {
              await scormFuncs.setScore(campaignResponse.score);
            } catch (e: any) {
              const err = new ErrorWithData({
                data: e,
                message: 'an error occurred when calling scormFuncs.setScore',
              });
              trackException({
                errorCode: 'SCORMFUNCS_FAILURE',
                exception: { exception: err },
                addTrackingDetailsToException: true,
              });
              throw err;
            }
            try {
              await scormFuncs.doQuit(null);
            } catch (e: any) {
              const err = new ErrorWithData({
                data: e,
                message: 'an error occurred when calling scormFuncs.doQuit',
              });
              trackException({
                errorCode: 'SCORMFUNCS_FAILURE',
                exception: { exception: err },
                addTrackingDetailsToException: true,
              });
              throw err;
            }
          }
        } else {
          try {
            await scormFuncs.setScore(100);
          } catch (e: any) {
            const err = new ErrorWithData({
              data: e,
              message: 'an error occurred when calling scormFuncs.setScore',
            });
            trackException({
              errorCode: 'SCORMFUNCS_FAILURE',
              exception: { exception: err },
              addTrackingDetailsToException: true,
            });
            throw err;
          }
          try {
            await scormFuncs.doQuit('completed');
          } catch (e: any) {
            const err = new ErrorWithData({
              data: e,
              message: 'an error occurred when calling scormFuncs.doQuit',
            });
            trackException({
              errorCode: 'SCORMFUNCS_FAILURE',
              exception: { exception: err },
              addTrackingDetailsToException: true,
            });
            throw err;
          }
        }
      }
    };
  } else {
    return async () => {
      console.log('Not a SCORM environment. Nothing to do here.');
    };
  }
}
