import { isAxiosError, isCancel } from 'axios';
import {
  CircuitState as CircuitStateEnum,
  ConsecutiveBreaker,
  ExponentialBackoff,
  ICancellationContext,
  IDisposable,
  TaskCancelledError,
  TimeoutStrategy,
  circuitBreaker,
  handleAll,
  handleWhen,
  isBrokenCircuitError,
  retry,
  timeout,
  wrap,
} from 'cockatiel';
import { AnyAction, Dispatch } from 'redux';
import { NetworkStatusActions } from 'redux-lib/actions/networkStatus';
import { CircuitState } from 'redux-lib/reducers/networkStatus';
import { v4 as uuid } from 'uuid';

const circuitBreakerPolicy = circuitBreaker(handleAll, {
  halfOpenAfter: 10000,
  breaker: new ConsecutiveBreaker(5),
});

export type PolicyFactory = {
  /**
   * Connect policy factory to be able to dispatch actions into redux store.
   * Should be called just after redux store is created during app initialization.
   * Subsequent calls will fail with an error.
   * @param dispatch redux dispatcher
   */
  configure: (dispatch: Dispatch<AnyAction>) => void;
  /**
   * Execute an async action using a retry policy (that is plugged into the redux store). Policy execution will always attempt to retry if the circuit-breaker
   * is open. If option `noRetry` is falsey, then execution will be cancelled and retried in the case of execution timeout/cancellation and responses that indicate network error.
   * If `signal` is passed in the options, and signals "abort", then retries will stop.
   * @param handler perform the async operation in this handler, passing `ctx.signal` to the async operation (to ensure that timeouts work correctly)
   * @param param1 policy options
   * @returns a promise that is resolved with the value of the promise that is tried in the handler, or rejected in the case of a complete failure
   */
  execute: <T = void>(
    handler: (ctx: ICancellationContext) => Promise<T>,
    opts?: PolicyFactoryExecuteOptions,
  ) => Promise<T>;
};

function getPolicyFactory(): PolicyFactory {
  let dispatchInstance: Dispatch<AnyAction> | undefined;
  let configured = false;

  const configure = (dsp: Dispatch<AnyAction>) => {
    if (configured) {
      throw Error('already configured');
    }
    configured = true;
    dispatchInstance = dsp;
    circuitBreakerPolicy.onStateChange((state) => {
      dsp(NetworkStatusActions.circuitBreakerStateChange({ state: circuitStateToString(state) }));
    });
  };

  const getPolicy = ({ noRetry, dontSignalFailure, timeoutInterval = 1000 }: GetPolicyOptions) => {
    const policyId = uuid();
    const dispatch = getDispatch(dispatchInstance);
    dispatch(NetworkStatusActions.policyCreated({ policyId }));
    const timeoutPolicy = timeoutInterval > 0 ? timeout(timeoutInterval, TimeoutStrategy.Cooperative) : undefined;
    const retryPolicy = retry(
      handleWhen((err) => {
        console.log('retryPolicy error', err.message, err instanceof TaskCancelledError, err);
        if (noRetry) {
          const brokenCircuitError = isBrokenCircuitError(err);
          if (!brokenCircuitError && !dontSignalFailure) {
            dispatch(NetworkStatusActions.retryPolicyFailure({ policyId }));
          }
          return brokenCircuitError;
        } else {
          const shouldHandle =
            err instanceof TaskCancelledError ||
            isBrokenCircuitError(err) ||
            (isAxiosError(err) && (err.code === 'ECONNABORTED' || err.code === 'ERR_NETWORK')) ||
            isCancel(err);
          if (shouldHandle) {
            dispatch(NetworkStatusActions.retryPolicyRetry({ policyId }));
          } else if (!dontSignalFailure) {
            dispatch(NetworkStatusActions.retryPolicyFailure({ policyId }));
          }
          return shouldHandle;
        }
      }),
      {
        backoff: new ExponentialBackoff({
          initialDelay: 1000,
          exponent: 2,
          maxDelay: 5000,
        }),
      },
    );
    const policies = [
      retryPolicy,
      circuitBreakerPolicy,
      ...(timeoutPolicy ? ([timeoutPolicy] as const) : ([] as const)),
    ] as const;
    const policy = wrap(...policies);

    const listeners = [
      //retryPolicy.onRetry(() => dispatch(NetworkStatusActions.retryPolicyRetry({ policyId }))),

      timeoutPolicy?.onTimeout(() => dispatch(NetworkStatusActions.timeoutPolicyTimeout({ policyId }))),
      //retryPolicy.onSuccess(() => dispatch(NetworkStatusActions.retryPolicySuccess({ policyId }))),
    ].filter((v): v is IDisposable => v != null);
    const dispose = () => {
      listeners.forEach((l) => l.dispose());
      dispatch(NetworkStatusActions.policyDisposed({ policyId }));
    };
    return { policy, dispose };
  };

  const execute = async <T = void>(
    handler: (ctx: ICancellationContext) => Promise<T>,
    { noRetry, timeoutInterval = 5000, signal, dontSignalFailure }: PolicyFactoryExecuteOptions = {
      timeoutInterval: 5000,
    },
  ) => {
    const { policy, dispose } = getPolicy({ noRetry, timeoutInterval: timeoutInterval, dontSignalFailure });
    try {
      const returnVal = await policy.execute(handler, signal);
      return returnVal;
    } finally {
      dispose();
    }
  };

  return {
    configure,
    execute,
  };
}

type GetPolicyOptions = {
  /**
   * when set to true, won't retry requests, unless they
   * are caused by open circuit-breaker (i.e. we are sure that
   * they were not actually sent)
   */
  noRetry?: boolean;
  /**
   * set to true when you don't want the failed
   * request to cause dispatch of `NetworkStatusActions.retryPolicyFailure`
   * (which will be reported in the UI). For situations where
   * retry is managed by the caller (i.e. telemetry), but we still
   * want to use the policy for timeout and circuit-breaker stuff
   */
  dontSignalFailure?: boolean;
  /**
   * time to wait before executed items are timed out to be retried
   */
  timeoutInterval?: number;
};

export type PolicyFactoryExecuteOptions = GetPolicyOptions & {
  /**
   * AbortSignal which, if signalled, will abort execution
   */
  signal?: AbortSignal;
};

export const policyFactory = getPolicyFactory();
function getDispatch(dispatchInstance: Dispatch<AnyAction> | undefined): Dispatch<AnyAction> {
  if (!dispatchInstance) {
    throw Error('must call configure with a dispatch function before calling getPolicy');
  }
  const dispatch = dispatchInstance;
  return dispatch;
}

const circuitStateToString = (circuitState: CircuitStateEnum): CircuitState => {
  switch (circuitState) {
    case CircuitStateEnum.Closed:
      return 'closed';
    case CircuitStateEnum.Open:
      return 'open';
    case CircuitStateEnum.HalfOpen:
      return 'halfOpen';
    case CircuitStateEnum.Isolated:
      return 'isolated';
  }
};
