import './mouseEventPolyfill';

export interface DownloadFileOptions {
  blobParts: (string | ArrayBufferView | ArrayBuffer | Blob)[];
  name?: string;
  contentType?: string;
}

/**
 *
 * @param param0
 */
export function downloadFile({
  blobParts,
  name = 'file.txt',
  contentType = 'text/plain; charset=utf-8',
}: DownloadFileOptions): void {
  const { link, blobUrl } = (window.navigator as any).msSaveOrOpenBlob
    ? getIE11Link(blobParts, contentType, name)
    : getStandardLink(blobParts, contentType, name);

  setTimeout(() => {
    const evt = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    link.dispatchEvent(evt);
  }, 0);
  if (blobUrl) {
    setTimeout(() => {
      window.URL.revokeObjectURL(blobUrl);
    }, 4e4);
  }
}
function getStandardLink(
  blobParts: (string | ArrayBufferView | ArrayBuffer | Blob)[],
  contentType: string,
  name: string,
): { link: HTMLAnchorElement; blobUrl?: string } {
  const blob = new Blob(blobParts, { type: contentType });
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = name;
  link.rel = 'noopener';
  return { link, blobUrl };
}
function getIE11Link(
  blobParts: (string | ArrayBufferView | ArrayBuffer | Blob)[],
  contentType: string,
  name: string,
): { link: HTMLAnchorElement; blobUrl?: string } {
  const blob = new Blob(blobParts, { type: contentType });
  //const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  //link.href = blobUrl;
  link.download = name;
  link.rel = 'noopener';
  link.onclick = () => {
    (window.navigator as any).msSaveOrOpenBlob(blob, name);
  };
  return { link };
}
