import { UserDashboardConfig } from 'lib/api/apiTypes/UserDashboardConfig';
import { UserDashboardLoginResponse } from 'lib/api/apiTypes/UserDashboardLoginResponse';
import { UserDashboardActions } from 'redux-lib/actions/userDashboard';
import { createReducer } from 'typesafe-actions';

export interface UserDashboardState {
  dashboardData?: UserDashboardLoginResponse;
  dashboardConfig?: UserDashboardConfig;
}

const defaultState: UserDashboardState = {};

export const userDashboardReducer = createReducer<UserDashboardState, UserDashboardActions>(defaultState)
  .handleAction(UserDashboardActions.setDashboardData, (state, { payload }) => ({ ...state, dashboardData: payload }))
  .handleAction(UserDashboardActions.setDashboardConfig, (state, { payload }) => ({
    ...state,
    dashboardConfig: payload,
  }));
