import axios from 'lib/axios';
import { ErrorWithData } from '../errors/ErrorWithData';
import { UserDashboardLoginResponse } from './apiTypes/UserDashboardLoginResponse';
import { UserDashboardLoginRequest } from './apiTypes/UserDashboardLoginRequest';
import { policyFactory } from 'lib/axios/policyFactory';

const apiRootEndpoint = import.meta.env.REACT_APP_API_ROOT_URL;

export const fetchUserDashboardData = async (
  data: UserDashboardLoginRequest | undefined,
  organizationId: string,
  sessionId?: string,
): Promise<UserDashboardLoginResponse> => {
  if (!data && !sessionId) {
    throw new Error('Either data or sessionId must be provided');
  }
  if (!apiRootEndpoint) {
    throw new Error('REACT_APP_API_ROOT_URL not set');
  }
  const url = `${apiRootEndpoint}/training/lightDashboard/organizations/${organizationId}/users/login`;
  try {
    const response = await policyFactory.execute(
      ({ signal }) =>
        axios.post<UserDashboardLoginResponse>(url, data, {
          headers: sessionId ? { Authorization: `OTP ${sessionId}` } : {},
          signal,
        }),
      { timeoutInterval: 10000, noRetry: true },
    );
    // const response = await axios.post<UserDashboardLoginResponse>(url, data, {
    //     headers: sessionId ? { Authorization: `OTP ${sessionId}` } : {},
    // });
    return response.data;
  } catch (e: any) {
    throw new ErrorWithData({ data: e, message: getErrorMessage(e), isMessagePublic: true });
  }
};

const getErrorMessage = (error: any) => {
  const statusCode = error?.response?.status;
  if (statusCode === 401) {
    return 'Invalid credentials. Please try again.';
  }
  return 'Something went wrong. (User Dashboard API error)';
};
