import { ThemesAndLogos } from 'lib/api/fetchThemeConfig';
import { createReducer } from 'typesafe-actions';
import { InitializationActions } from '../actions/initialization';

export interface InitializationState {
  initialized: boolean;
  themeConfig?: ThemesAndLogos;
  hideModuleDuration?: boolean;
  error?: any;
}

const defaultState: InitializationState = { initialized: false };

export const initializationReducer = createReducer<InitializationState, InitializationActions>(defaultState)
  .handleAction(InitializationActions.initializationSuccess, (state) => ({ ...state, initialized: true }))
  .handleAction(InitializationActions.initializationFailure, (state, { payload }) => {
    return { ...state, error: payload };
  })
  .handleAction(InitializationActions.themeConfigReceived, (state, { payload }) => {
    return { ...state, themeConfig: payload };
  })
  .handleAction(InitializationActions.setHideModuleDuration, (state, { payload }) => {
    return { ...state, hideModuleDuration: payload };
  });
