import { useEffect, useRef } from 'react';

export const useClickOutside = <T extends HTMLElement>(callback: (e: MouseEvent) => void) => {
  const elRef = useRef<T>(null);
  const callbackRef = useRef<(e: MouseEvent) => void>();
  callbackRef.current = callback;

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (elRef.current && callbackRef.current && !elRef.current.contains(e.target as Node)) {
        callbackRef.current(e);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return elRef;
};
