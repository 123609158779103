import { LanguagesResponse } from 'lib/api/apiTypes/LanguagesResponse';
import { fetchLanguages } from 'lib/api/fetchLanguages';
import { prepareErrorForSerialization } from 'lib/errors/prepareErrorForSerialization';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { InitializationActions } from 'redux-lib/actions/initialization';
import { trackException } from 'tracking';

export function* getLanguages(): SagaIterator {
  try {
    const languages: LanguagesResponse = yield call(async () => fetchLanguages());
    // // TODO: remove this filter when en-US is better supported
    // const filteredLangs: LanguagesResponse = {
    //     ...languages,
    //     languages: languages.languages.filter((v) => v.code !== 'en-US'),
    // };
    yield put(InitializationActions.languagesReceived(languages));
  } catch (err) {
    trackException({
      exception: { exception: err as any },
      errorCode: 'FETCH_LANGUAGES_FAILED',
      addTrackingDetailsToException: true,
    });

    yield put(
      InitializationActions.initializationFailure({
        type: 'Error',
        error: prepareErrorForSerialization(err),
      }),
    );
  }
}
