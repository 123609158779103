import { useTexts } from 'lib/hooks/useTexts';
import React, { FC } from 'react';
import { useTheme } from 'styled-components';
import TabButton from './styled/TabButton';

interface TabButtonProps {
  completedNumber: number;
  remainingNumber: number;
  showCompleted: boolean;
  setShowCompleted: (s: boolean) => void;
}

const TabButtons: FC<React.PropsWithChildren<TabButtonProps>> = ({
  completedNumber,
  remainingNumber,
  showCompleted,
  setShowCompleted,
}) => {
  const [remainingLessons, completedLessons] = useTexts(['remainingLessons', 'completedLessons']);
  const theme = useTheme();

  const remainingColor = showCompleted ? theme.colors.text.color : theme.colors.highlight.color;
  const remainingBgColor = showCompleted ? 'transparent' : theme.colors.dangerBg.color;
  const completedColor = showCompleted
    ? theme.colors.highlight.color
    : completedNumber === 0
      ? 'rgba(0, 0, 0, 0.2)'
      : theme.colors.text.color;
  const completedBgColor = showCompleted ? theme.colors.dangerBg.color : 'transparent';

  return (
    <div>
      <TabButton $color={remainingColor} bgColor={remainingBgColor} onClick={() => setShowCompleted(false)}>
        {remainingLessons}
        <span>{remainingNumber}</span>
      </TabButton>
      <TabButton
        $color={completedColor}
        bgColor={completedBgColor}
        inactive={completedNumber === 0}
        onClick={() => completedNumber !== 0 && setShowCompleted(true)}
      >
        {completedLessons}
        <span>{completedNumber}</span>
      </TabButton>
    </div>
  );
};

export default TabButtons;
