import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from 'pages/Dashboard';
import { BrowserRouter } from './BrowserRouter';
import { NotFound } from 'components/error/error-screens/NotFound';
import { GlobalStyle } from 'AppStyles';
import { UserDashboardMain } from 'pages/UserDashboard';
import { SpinToWin } from 'pages/SpinToWin';

// if more routes are added here, please ensure that
// the regexp at src/lib/axios/getTenantIdentifier.ts is updated
// to match the tenantId segment
export const Router: FC<React.PropsWithChildren> = () => {
  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <Routes>
          <Route path={`/dashboard/:campaignId/:userId`} element={<Dashboard />} />
          <Route path={`/:tenantId/dashboard/:campaignId/:userId`} element={<Dashboard />} />
          <Route path={`/dashboard/:campaignId`} element={<Dashboard />} />
          <Route path={`/:tenantId/dashboard/:campaignId`} element={<Dashboard />} />
          <Route path={`/user-dashboard/:orgId/*`} element={<UserDashboardMain />} />
          <Route path={`/:tenantId/user-dashboard/:orgId/*`} element={<UserDashboardMain />} />
          <Route path={'/dashboard/reward/:campaignId/:userId'} element={<SpinToWin />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};
