import React, { FC } from 'react';
import { Popup } from 'components/Popup';
import styled from 'styled-components';
import { ReactComponent as OtpIcon } from 'assets/icons/otp-icon.svg';
import { SecondaryButton } from 'components/styled/SecondaryCutCornerButton';
import { substituteTextsHandlebars, useTexts } from 'lib/hooks/useTexts';
import { Button, Buttons, Header, Title } from './styled';

export const ConfirmationPopup: FC<
  React.PropsWithChildren<{
    code: string;
    customIdName: string;
    onConfirm: () => void;
    onCancel: () => void;
  }>
> = ({ code, customIdName, onConfirm, onCancel }) => {
  const [headerText, desc1Text, desc2Text, confirmText, cancelText] = useTexts([
    'confirmationPopupHeader',
    'confirmationPopupDescription1',
    'confirmationPopupDescription2',
    'confirmationPopupConfirmButton',
    'cancel',
  ]);

  return (
    <Popup width="510px">
      <Header>
        <OtpIcon />
        <Title>{headerText}</Title>
      </Header>
      <Description>{substituteTextsHandlebars(desc1Text, 'desc1Text', { customIdName })}</Description>
      <Code>{code}</Code>
      <Description>{desc2Text}</Description>
      <Buttons>
        <SecondaryButton onClick={onCancel}>{cancelText}</SecondaryButton>
        <Button onClick={onConfirm}>{confirmText}</Button>
      </Buttons>
    </Popup>
  );
};

const Code = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const Description = styled.p`
  font-size: 14px;
`;
