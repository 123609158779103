import { call } from 'redux-saga/effects';
import { ErrorWithData } from 'lib/errors/ErrorWithData';
import { SagaDelegatedYieldReturnType } from 'lib/redux-saga/SagaDelegatedYieldReturnType';
import { AxiosResponse } from 'axios';
import axios from 'lib/axios';
import { policyFactory } from 'lib/axios/policyFactory';

export interface GetOutthinkLearnerIdOptions {
  studentId: string;
  tenantId: string;
  campaignId: string;
}

const fakeCampaignEndpoint = '/api/scormLearnerIdResponse.json';

export function* getOutthinkLearnerId({
  studentId,
  tenantId,
  campaignId,
}: GetOutthinkLearnerIdOptions): SagaDelegatedYieldReturnType<string> {
  const apiUrl = getAPIUrl(studentId, campaignId);

  const apiEndpoint = apiUrl || fakeCampaignEndpoint;

  try {
    const response: AxiosResponse<{
      learnerId: string;
    }> = yield call(() =>
      policyFactory.execute(
        (ctx) =>
          axios.get<{ learnerId: string }>(apiEndpoint, {
            validateStatus: (status) => [404, 200].includes(status),
            signal: ctx.signal,
          }),
        {
          timeoutInterval: 10000,
        },
      ),
    );
    if (response.status === 404) {
      throw new ErrorWithData({
        data: response.data,
        isMessagePublic: true,
        message: `Student ${studentId} not found for campaign ${campaignId} (tenant ${tenantId})`,
      });
    }
    return response.data.learnerId;
  } catch (e) {
    throw new ErrorWithData({ data: e, message: 'Learner ID endpoint error' });
  }
}
function getAPIUrl(studentId: string, campaignId: string) {
  const envUrl = import.meta.env.REACT_APP_OT_LMS_LEARNER_ID_ENDPOINT;
  if (!envUrl) {
    return undefined;
  }
  const url = new URL(`${envUrl}/${encodeURIComponent(studentId)}`);
  url.searchParams.set('campaignId', campaignId);
  return url.href;
}
