import styles from './error-styles.module.css';
import im from './images/im2_medium.jpg';
import { ErrorJSONDownloadLink } from '../ErrorJSONDownloadLink';
import { Clipboard } from 'components/Clipboard';
import { Expander } from './Expander';
import { isErrWithTrackingDetails } from 'tracking';
import { prepareErrorForSerialization } from 'lib/errors/prepareErrorForSerialization';

function getPublicMessages(error: any): string[] {
  if (error == null) {
    return [];
  }

  const subErrors = getPublicMessages(error.data);
  return typeof error.isMessagePublic === 'boolean' && typeof error.message === 'string' && error.isMessagePublic
    ? [...subErrors, error.message]
    : subErrors;
}

export const DefaultError: React.FC<
  React.PropsWithChildren<{
    error: any;
  }>
> = ({ error }) => {
  const messages = getPublicMessages(error);
  const message: string | undefined = messages.length > 0 ? messages.join(', ') : undefined;
  const trackingDetails = isErrWithTrackingDetails(error) ? error.trackingDetails : undefined;
  const clipboardErrorDetails = JSON.stringify(
    {
      error: prepareErrorForSerialization(error),
      ...trackingDetails,
    },
    null,
    2,
  );

  return (
    <div className={styles.main}>
      <h1 className={styles.heading}>Oops, something went wrong...</h1>
      <p className={styles.body}>
        It seems we are experiencing a technical issue. Please try reloading the page. If this does not work notify your
        support team.
      </p>
      <img className={styles.image} src={im} />
      <div className={styles['error-details']}>
        <Expander title="Technical details">
          {message && <p>{message}</p>}
          {trackingDetails && (
            <p>
              <div>Error Code : {trackingDetails.errorCode}</div>
              <div>Tracking ID : {trackingDetails.trackingID}</div>
            </p>
          )}
          <p>
            <span>Copy error details to clipboard:&nbsp;</span>
            <span>
              <Clipboard text={clipboardErrorDetails} title="Copy error details to the clipboard" />
            </span>
          </p>
          <p className={styles['download-error-details']}>
            <ErrorJSONDownloadLink error={error} filename="error-report.json">
              Download error report
            </ErrorJSONDownloadLink>
          </p>
        </Expander>
      </div>
    </div>
  );
};
