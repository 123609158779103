import { downloadFile } from 'lib/download/downloadFile';
import { prepareErrorForSerialization } from 'lib/errors/prepareErrorForSerialization';
import React from 'react';

export const ErrorJSONDownloadLink: React.FC<React.PropsWithChildren<{ error: any; filename: string }>> = ({
  children,
  error,
  filename,
}) => {
  const downloadReport = React.useCallback(() => {
    const err = prepareErrorForSerialization(error);
    const data = {
      error: err,
      location: window.location.href,
    };
    downloadFile({
      blobParts: [JSON.stringify(data)],
      contentType: 'application/json',
      name: filename,
    });
  }, [error, filename]);
  return (
    <a
      style={{ color: '#3c3c8d', cursor: 'pointer' }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        downloadReport();
      }}
      href={filename}
    >
      {children}
    </a>
  );
};
