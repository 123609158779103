import Axios, { AxiosInstance } from 'axios';
import { getTenantIdentifier } from './getTenantIdentifier';
// import { policyFactory } from './addCockatielRetryPolicy/policies';
// import { addCockatielRetryPolicy } from './addCockatielRetryPolicy';

function getAxiosInstanceImpl(): AxiosInstance {
  const axiosInstance = Axios.create();
  const tenantIdentifier = getTenantIdentifier();
  if (tenantIdentifier) {
    axiosInstance.interceptors.request.use(async (config) => {
      const headers: any = config.headers ?? {};
      headers.TrainingDeliveryPath = tenantIdentifier;
      config.headers = headers;
      return config;
    });
  }
  // const enhancedAxios: AxiosInstanceWithPolicySupport = axiosInstance as AxiosInstanceWithPolicySupport;
  // enhancedAxios.withPolicy = async <T = void>(
  //     handler: (axios: AxiosInstance) => Promise<T>,
  //     { noRetry, timeoutPeriod = 5000 }: { noRetry?: boolean; timeoutPeriod?: number } = { timeoutPeriod: 5000 },
  // ): Promise<T> => {
  //     const { policy, dispose } = policyFactory.getPolicy({ noRetry, timeoutPeriod });
  //     const ax = addCockatielRetryPolicy({ axios: axiosInstance, policy });
  //     try {
  //         const v = await handler(ax);
  //         return v;
  //     } finally {
  //         dispose();
  //     }
  // };
  // return enhancedAxios;
  return axiosInstance;
}

// type AxiosInstanceWithPolicySupport = AxiosInstance & {
//     withPolicy: <T = void>(
//         handler: (axios: AxiosInstance) => Promise<T>,
//         policyOpts?: {
//             noRetry?: boolean;
//             timeoutPeriod?: number;
//         },
//     ) => Promise<T>;
// };

const instance = getAxiosInstanceImpl();
export default instance;
