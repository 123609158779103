import { FC, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import { Lesson, LessonStatus } from 'lib/api/apiTypes/Lesson';
import { useTexts } from 'lib/hooks/useTexts';
import Pill from './styled/Pill';
import { useSelector } from 'react-redux';
import { RootState } from 'redux-lib/RootState';

interface ModuleTileProps {
  moduleData: Lesson;
  fullWidth?: boolean;
  idx: number;
}

const moduleStatusTextIds = ['notStarted', 'inProgress', 'complete'] as const;

function getStatusIdx(status: string): number {
  const idx = moduleStatusTextIds.findIndex((t) => t === status);
  if (idx < 0) {
    console.error('Module status not found');
  }
  return idx;
}

const moduleStatusMap: Record<LessonStatus, (typeof moduleStatusTextIds)[number] | null> = {
  Created: 'notStarted',
  Started: 'inProgress',
  Completed: 'complete',
  Expired: null,
};

const ModuleTile: FC<ModuleTileProps> = ({ moduleData, fullWidth, idx }) => {
  const hideDuration = useSelector((state: RootState) => state.initialization.hideModuleDuration);
  const moduleStatusTexts = useTexts(moduleStatusTextIds as unknown as string[]);
  const [durationMinutes] = useTexts(['durationMinutes'], idx);

  const moduleStatus = moduleStatusMap[moduleData.status];
  const theme = useTheme();

  const statusBgColors: Record<LessonStatus, string> = useMemo(
    () => ({
      Created: theme.colors.highlight4.color,
      Started: theme.colors.warning.color,
      Completed: theme.colors.success.color,
      Expired: 'transparent',
    }),
    [theme],
  );

  if (!moduleStatus) {
    return null;
  }

  return (
    <TileContainer href={moduleData.url} fullWidth={fullWidth}>
      <TileImg>
        <img src={moduleData.previewImage || '/api/images/module-image.png'} alt="not started illustration" />
      </TileImg>
      <DescriptionSection>
        <div>
          <ModuleTitle>{moduleData.name}</ModuleTitle>
          <ModuleDesc>{moduleData.description}</ModuleDesc>
        </div>
        <ModuleLabels>
          {!hideDuration && (
            <Pill $color={theme.colors.highlight4.color} bgColor={theme.colors.infoBg.color}>
              {durationMinutes}
            </Pill>
          )}
          <Pill $color={theme.colors.secondary.color} bgColor={statusBgColors[moduleData.status]}>
            {moduleStatusTexts[getStatusIdx(moduleStatus)]}
          </Pill>
        </ModuleLabels>
      </DescriptionSection>
    </TileContainer>
  );
};

const TileImg = styled.div`
  flex-shrink: 0; // IE fix
`;

const DescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
`;

const ModuleTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 14px;
`;

const ModuleDesc = styled.div`
  line-height: 1.25;
  margin-bottom: 20px;
`;

const ModuleLabels = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TileContainer = styled.a<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.fullWidth ? 'row' : 'column')};
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  width: ${(props) => (props.fullWidth ? '100%' : 'calc((100% - 20px) / 2)')};
  border: 1px solid #dfdfdf;
  cursor: pointer;
  transition: all 250ms;

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.highlight.color};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-4px);
  }

  & > * {
    width: ${(props) => (props.fullWidth ? 'calc((100% - 20px) / 2)' : '100%')};

    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }

  ${TileImg} {
    padding-right: ${(props) => (props.fullWidth ? '20px' : '0')};

    @media screen and (max-width: 800px) {
      padding-right: 0;
    }
  }

  ${DescriptionSection} {
    padding: ${(props) => (props.fullWidth ? '44px 0' : '20px 0 0')};

    @media screen and (max-width: 800px) {
      padding: 16px 0 0;
    }
  }

  ${ModuleLabels} {
    padding-right: ${(props) => (props.fullWidth ? '20px' : '0')};

    @media screen and (max-width: 800px) {
      padding-right: 0;
    }
  }

  @media screen and (max-width: 800px) {
    width: calc((100% - 20px) / 2);
    flex-direction: column;
  }

  @media screen and (max-width: 650px) {
    width: 100%;
  }
`;

export default ModuleTile;
