import { put, select, takeEvery } from 'redux-saga/effects';
import { TextsActions } from 'redux-lib/actions/texts';
import { RootState } from 'redux-lib/RootState';
import {
  CampaignResponse,
  isPassRateRequiredCampaign,
  isTrainingExpiredCampaign,
} from 'lib/api/apiTypes/CampaignResponse';
import { progressSelector } from 'redux-lib/selectors/progressSelector';

export function* textSubstitutionSaga() {
  yield takeEvery(TextsActions.fetchResult, function* () {
    const progressState: ReturnType<typeof progressSelector> = yield select(progressSelector);
    const progressPercentage: number = progressState.progressPercentage;
    const completedPercent: string = progressPercentage.toString(10);
    const duration: string[] = yield select((state: RootState) =>
      state.campaign.lessons.map((l) => l.completionMinutes.toString()),
    );
    const campaignResponse: CampaignResponse | undefined = yield select(
      (state: RootState) => state.campaign.campaignResponse,
    );
    const score: string = isPassRateRequiredCampaign(campaignResponse) ? campaignResponse.score.toFixed(0) : '';
    const attempts: string = isPassRateRequiredCampaign(campaignResponse) ? campaignResponse.attempts.toFixed(0) : '';
    const highestScore: string = isPassRateRequiredCampaign(campaignResponse)
      ? campaignResponse.highestScore.toFixed(0)
      : '';
    const remainingAttempts: string = isPassRateRequiredCampaign(campaignResponse)
      ? campaignResponse.remainingAttempts.toFixed(0)
      : '';
    const passingScore: string = isPassRateRequiredCampaign(campaignResponse)
      ? campaignResponse.passingScore.toFixed(0)
      : '';

    const selectedLanguage: string | undefined = yield select((state: RootState) => state.campaign.selectedLanguage);

    yield put(
      TextsActions.addSubstitutions({
        completedPercent,
        duration,
        score,
        attempts,
        highestScore,
        remainingAttempts,
        passingScore,
        completionDeadlineDate: isTrainingExpiredCampaign(campaignResponse)
          ? new Date(Date.parse(campaignResponse.endDate)).toLocaleDateString(selectedLanguage, {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })
          : '',
      }),
    );
  });
}
