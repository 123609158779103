import styled from 'styled-components';
import { DestyledButton } from './DestyledButton';

interface TabButtonProps {
  bgColor?: string;
  $color?: string;
  inactive?: boolean;
}

const TabButton = styled(DestyledButton)<TabButtonProps>`
  display: inline-block;
  padding: 8px 16px;
  font-weight: 600;
  ${(props) => props.theme.fontFamilyCSS.callToAction}
  color: ${(props) => props.$color || props.theme.colors.text.color};
  background-color: ${(props) => props.bgColor || 'transparent'};
  border-radius: 24px;
  font-size: 20px;
  cursor: ${(props) => (props.inactive ? 'default' : 'pointer')};
  user-select: none;
  transition: all 150ms ease-in-out;

  & > span {
    height: 30px;
    min-width: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 9px 1px;
    margin-left: 8px;
    color: #fff;
    background-color: ${(props) => props.$color || props.theme.colors.primary.color};
    border-radius: 50%;
  }

  &:not(:last-child) {
    margin-right: 12px;
  }

  @media screen and (max-width: 625px) {
    font-size: 16px;
    margin-bottom: 15px;

    &:not(:last-child) {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    text-align: center;
  }
`;

export default TabButton;
