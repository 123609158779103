export const delay = (timeMs: number, signal?: AbortSignal) => {
  return new Promise<void>((resolve, reject) => {
    rejectIfAborted(signal, reject);
    const timeout = setTimeout(() => {
      resolve();
      signal?.removeEventListener('abort', handler);
    }, timeMs);
    const handler = () => {
      console.log('aborted');
      clearTimeout(timeout);
      rejectIfAborted(signal, reject);
    };
    signal?.addEventListener('abort', handler, { once: true });
  });
};
function rejectIfAborted(signal: AbortSignal | undefined, reject: (reason?: any) => void) {
  try {
    signal?.throwIfAborted();
  } catch (e) {
    reject(e);
  }
}
