import { createStore as crStore, applyMiddleware, Middleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { isErrWithTrackingDetails, trackException } from 'tracking';
import { identity } from '../lib/functional/identity';
import { InitializationActions } from './actions/initialization';
import { getRootReducer } from './getRootReducer';
import { getRootSaga } from './sagas/getRootSaga';
import { policyFactory } from 'lib/axios/policyFactory';

const composeEnhancers =
  import.meta.env.DEV || import.meta.env.REACT_APP_OT_REDUX_TOOLS === 'on' ? composeWithDevTools({}) : compose;

export const createStore = (...middleware: Middleware[]) => {
  const sagaMiddleware = createSagaMiddleware({
    onError: (e) => {
      if (!isErrWithTrackingDetails(e)) {
        trackException({
          errorCode: 'UNCAUGHT_SAGA_ERROR',
          exception: { exception: e },
          addTrackingDetailsToException: true,
        });
      }
      store.dispatch(InitializationActions.initializationFailure(e));
    },
  });
  const store = crStore(
    compose(identity)(getRootReducer()),
    composeEnhancers(applyMiddleware(sagaMiddleware, ...middleware)),
  );
  policyFactory.configure(store.dispatch);
  sagaMiddleware.run(getRootSaga);
  return store;
};
