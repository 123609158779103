import { fetchThemeConfig, fetchThemeConfigUrl, ThemeConfigResponse, ThemesAndLogos } from 'lib/api/fetchThemeConfig';
import { ErrorWithData } from 'lib/errors/ErrorWithData';
import { prepareErrorForSerialization } from 'lib/errors/prepareErrorForSerialization';
import { retryWithBackoff } from 'lib/redux-saga/effects/retryWithBackoff';
import { call, put } from 'redux-saga/effects';
import { InitializationActions } from 'redux-lib/actions/initialization';
import { trackException } from 'tracking';

export function* getThemeConfig({
  campaignId,
  userId,
  organizationId,
}: {
  campaignId?: string;
  userId?: string;
  organizationId?: string;
}) {
  let themeConfig: ThemeConfigResponse;
  try {
    themeConfig = yield retryWithBackoff(() => fetchThemeConfigUrl({ campaignId, userId, organizationId }));
  } catch (err) {
    const errWithData = new ErrorWithData({
      data: err,
      message: 'Could not get themeConfigUrl from API',
      isMessagePublic: true,
    });

    trackException({
      errorCode: 'FETCH_THEME_CONFIG_URL_FAILED',
      exception: {
        exception: errWithData,
      },
      addTrackingDetailsToException: true,
    });

    yield put(InitializationActions.initializationFailure(prepareErrorForSerialization(errWithData)));
    return;
  }
  const hideModuleDuration = themeConfig?.displayModuleDuration === false;
  yield put(InitializationActions.setHideModuleDuration(hideModuleDuration));

  const themeConfigUrl = themeConfig?.themeUrl;

  if (themeConfigUrl) {
    try {
      const response: ThemesAndLogos | null = yield call(() => fetchThemeConfig(themeConfigUrl));

      if (response) {
        yield put(InitializationActions.themeConfigReceived(response));
      }
    } catch {
      console.warn('ThemeConfig not available');
    }
  }
}
