import { AxiosResponse } from 'axios';
import Axios from 'axios';
import { policyFactory } from 'lib/axios/policyFactory';

const apiEndpoint = import.meta.env.REACT_APP_MAINTENANCE_MODE_ENDPOINT;
export type MaintenanceModeStatusResponse = {
  enabled: boolean;
  endTime: string | null;
};

// let count = 0;

export const fetchMaintenanceModeStatus = async (): Promise<MaintenanceModeStatusResponse> => {
  // if (count++ > 0) {
  //     return {
  //         enabled: true,
  //         endTime: new Date().toISOString(),
  //     };
  // }
  if (!apiEndpoint) {
    return {
      enabled: false,
      endTime: null,
    };
  }
  try {
    // const response: AxiosResponse<MaintenanceModeStatusResponse> = await
    // Axios.get(apiEndpoint, {
    //     timeout: 10000,
    //     validateStatus: () => true,
    // });
    const response: AxiosResponse<MaintenanceModeStatusResponse> = await policyFactory.execute(
      ({ signal }) =>
        Axios.get(apiEndpoint, {
          signal,
        }),
      { timeoutInterval: 10000 },
    );
    return response.data;
  } catch {
    return {
      enabled: false,
      endTime: null,
    };
  }
};
