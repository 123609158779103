import { call } from 'redux-saga/effects';
import { DashboardResponse } from 'lib/api/apiTypes/DashboardResponse';
import { SCORMFuncs } from 'lib/SCORM/SCORMFuncs';
import { getModuleOrigins } from './getModuleOrigins';
import { trackException } from 'tracking';
import { ErrorWithData } from 'lib/errors/ErrorWithData';

export function* syncSCORMState(dashboardResponse: DashboardResponse, scormFuncs: SCORMFuncs) {
  const moduleOrigins = getModuleOrigins(dashboardResponse);
  for (const origin of moduleOrigins) {
    try {
      yield call(() => scormFuncs.addPermittedOrigin(origin));
    } catch (e: any) {
      const err = new ErrorWithData({
        data: e,
        message: 'an error occurred when calling scormFuncs.addPermittedOrigin',
      });
      trackException({
        errorCode: 'SCORMFUNCS_FAILURE',
        exception: { exception: err },
        addTrackingDetailsToException: true,
      });
      throw err;
    }
  }
  const allModulesWithIds = dashboardResponse.modules.flatMap((lesson) => {
    const url = new URL(lesson.url);
    const moduleId = url.searchParams.get('moduleId') ?? url.searchParams.get('m');
    if (!moduleId) {
      throw Error(`could not parse moduleId from url ${lesson.url}`);
    }
    return [{ module: lesson, moduleId }];
  });
  const isCurriculumCampaign = allModulesWithIds.some(
    (m) => m.module.name === 'Initial Assessment' || m.module.name === 'Final Assessment',
  );
  const allLessonsWithIds = allModulesWithIds.filter(
    (m) => m.module.name !== 'Initial Assessment' && m.module.name !== 'Final Assessment',
  );
  if (allLessonsWithIds.length > 0) {
    const moduleIdsToRegister = allLessonsWithIds.map((m) => m.moduleId);
    try {
      yield call(() => scormFuncs.registerModules(moduleIdsToRegister));
    } catch (e: any) {
      const err = new ErrorWithData({
        data: e,
        message: 'an error occurred when calling scormFuncs.registerModules',
      });
      trackException({
        errorCode: 'SCORMFUNCS_FAILURE',
        exception: { exception: err },
        addTrackingDetailsToException: true,
      });
      throw err;
    }
  }
  const completedModuleIds = allLessonsWithIds.flatMap((m) => (m.module.status === 'Completed' ? [m.moduleId] : []));
  try {
    yield call(() => scormFuncs.setCampaignType(isCurriculumCampaign ? 'curriculum' : 'instant'));
  } catch (e: any) {
    const err = new ErrorWithData({
      data: e,
      message: 'an error occurred when calling scormFuncs.setCampaignType',
    });
    trackException({
      errorCode: 'SCORMFUNCS_FAILURE',
      exception: { exception: err },
      addTrackingDetailsToException: true,
    });
    throw err;
  }
  for (const moduleId of completedModuleIds) {
    try {
      yield call(() => scormFuncs.setModuleCompleted(moduleId));
    } catch (e: any) {
      const err = new ErrorWithData({
        data: e,
        message: 'an error occurred when calling scormFuncs.setModuleCompleted',
      });
      trackException({
        errorCode: 'SCORMFUNCS_FAILURE',
        exception: { exception: err },
        addTrackingDetailsToException: true,
      });
      throw err;
    }
  }
}
