import React, { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux-lib/RootState';
import { CampaignActions } from 'redux-lib/actions/campaign';
import { useClickOutside } from 'lib/hooks/useClickOutside';
import { useTexts } from 'lib/hooks/useTexts';

interface LangSelectorProps {
  showPopup?: boolean;
  /* Delay in ms before showing the popup */
  popupDelay?: number;
}

export const LangSelector: FC<React.PropsWithChildren<LangSelectorProps>> = ({ showPopup, popupDelay = 0 }) => {
  const [langPopupMessage] = useTexts(['langPopupMessage']);

  const [dropdownActive, setDropdownActive] = React.useState(false);
  const [popupShown, setPopupShown] = React.useState(false);

  const selectedLang = useSelector((state: RootState) => state.campaign.selectedLanguage);
  const defaultLang = useSelector((state: RootState) => state.campaign.defaultLanguage);
  const languages = useSelector((state: RootState) => state.campaign.languages || []);
  const dispatch = useDispatch();
  const clickOutsideRef = useClickOutside<HTMLDivElement>(() => {
    if (dropdownActive) {
      setDropdownActive(false);
    }
  });
  const theme = useTheme();

  const selectLang = (langCode: string) => {
    if (langCode !== (selectedLang || defaultLang)) {
      dispatch(CampaignActions.languageSelected(langCode));
    }
  };

  React.useEffect(() => {
    if (showPopup && popupDelay) {
      setTimeout(() => {
        setPopupShown(true);
      }, popupDelay);
    }
  }, [showPopup, popupDelay]);

  React.useEffect(() => {
    const onClick = () => {
      setPopupShown(false);
    };
    document.addEventListener('click', onClick);
    return () => {
      document.removeEventListener('click', onClick);
    };
  }, []);

  return (
    <LangSelectorContainer onClick={() => setDropdownActive(!dropdownActive)} ref={clickOutsideRef}>
      <Popup show={popupShown}>{langPopupMessage}</Popup>
      <LangSelectorInnerContainer>
        <div>{getShortCode(selectedLang || defaultLang)}</div>
        <DwnArw color={theme.colors.text.color}></DwnArw>
        <Dropdown open={dropdownActive}>
          <UL>
            {languages.map((lang) => (
              <LI key={lang.code} onClick={() => selectLang(lang.code)}>
                {`${lang.name} - ${getShortCode(lang.code)}`}
              </LI>
            ))}
          </UL>
        </Dropdown>
      </LangSelectorInnerContainer>
    </LangSelectorContainer>
  );
};

const DownArrow: FC<React.PropsWithChildren<{ className?: string; color: string }>> = ({ className, color }) => {
  return (
    <svg className={className} width="13px" height="8px" viewBox="0 0 13 8" version="1.1">
      <g id="Design-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
        <g id="Slide-1.1" transform="translate(-1528.000000, -47.000000)" stroke={color} strokeWidth="1.6">
          <g id="-language" transform="translate(1493.000000, 38.000000)">
            <polyline
              id="Path"
              transform="translate(41.500000, 13.000000) rotate(-270.000000) translate(-41.500000, -13.000000) "
              points="39 18 44 13 39 8"
            ></polyline>
          </g>
        </g>
      </g>
    </svg>
  );
};
const DwnArw = styled(DownArrow)`
  margin-left: 8px;
  width: 14px;
  height: 9px;
`;

function getShortCode(code: string): string {
  const codeArr = code.split('-');
  return codeArr[0].toUpperCase();
}

const LangSelectorInnerContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  color: ${(props) => props.theme.colors.text.color};
  padding-bottom: 4px;
  padding-top: 4px;
  padding-right: 10px;
  padding-left: 10px;
`;

const LangSelectorContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 999;
  top: 9px;
  right: 2.4rem;
  padding-bottom: 8px;
  padding-top: 8px;
  color: ${(props) => props.theme.colors.text.color};
  font-size: 18px;
  letter-spacing: 0.22px;
  cursor: pointer;
  user-select: none;

  @media screen and (max-width: 500px) {
    position: relative;
    top: auto;
    right: auto;
    font-size: 16px;
  }
`;

const Dropdown = styled.div<{ open: boolean }>`
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.open ? '1' : '0')};
  position: absolute;
  right: 0;
  top: 100%;
  min-width: 60px;
  color: ${(props) => props.theme.colors.text.color};
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 0 1px rgba(48, 48, 48, 0.7);
  font-size: 18px;
  padding: 10px 0;
  text-align: right;
  pointer-events: ${(props) => (props.open ? 'auto' : 'none')};
  transition:
    visibility 250ms,
    opacity 250ms;
`;

const UL = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
`;

const LI = styled.li`
  padding: 0.5em 1.3em;
  white-space: nowrap;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const Popup = styled.div<{
  show: boolean;
}>`
  position: absolute;
  right: calc(100% + 16px);
  max-width: 260px;
  background-color: ${(props) => props.theme.colors.highlight4.color};
  font-size: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 24px;
  padding-left: 24px;
  text-align: right;
  pointer-events: auto;
  z-index: -100;
  color: ${(props) => props.theme.colors.secondary.color};
  white-space: nowrap;
  transform: translateY(-50%);
  top: 50%;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  line-height: 1;

  transition: opacity 0.2s ease-in-out;
  opacity: ${(props) => (props.show ? 1 : 0)};

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: -6px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid ${(props) => props.theme.colors.highlight4.color};
  }
`;
