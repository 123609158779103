import React, { useState } from 'react';
import { ReactComponent as ClipboardIcon } from './icons/clipboard.svg';
import { ReactComponent as CheckMark } from './icons/check-mark.svg';
import { useCopyToClipboard } from './hooks/useCopyToClipboard';
import { DestyledButton } from 'components/styled/DestyledButton';
import { delay } from 'lib/promises/delay';

export const Clipboard: React.FC<React.PropsWithChildren<{ text: string; title?: string }>> = ({ text, title }) => {
  const copyToClipboardHandler = useCopyToClipboard(text);
  const [showCopied, setShowCopied] = useState(false);

  return copyToClipboardHandler ? (
    <DestyledButton
      style={{
        position: 'relative',
        width: '20px',
        height: '20px',
        cursor: 'pointer',
      }}
      title={title}
      type="button"
      onClick={() => {
        copyToClipboardHandler().then(async () => {
          try {
            setShowCopied(true);
            await delay(1000);
          } finally {
            setShowCopied(false);
          }
        });
      }}
    >
      <ClipboardIcon
        style={{
          width: '100%',
          height: '100%',
          fill: '#adadad',
          top: 0,
          left: 0,
          position: 'absolute',
          opacity: showCopied ? 0 : 1,
          transition: 'opacity 500ms linear',
        }}
      />
      <CheckMark
        style={{
          width: '100%',
          height: '100%',
          stroke: 'green',
          top: 0,
          left: 0,
          position: 'absolute',
          opacity: !showCopied ? 0 : 1,
          transition: 'opacity 500ms linear',
        }}
      />
    </DestyledButton>
  ) : null;
};
