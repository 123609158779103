import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux-lib/RootState';
import { useTenantIdentifierFromBrowserLocation } from './useTenantIdentifierFromBrowserLocation';

export function useLessons() {
  const tenantIdentifier = useTenantIdentifierFromBrowserLocation();

  const lessonsFromState = useSelector((state: RootState) => state.campaign.lessons);
  const lessons = React.useMemo(
    () => lessonsFromState.map((x) => ({ ...x, url: addTenantIdentifierSearchParam(x.url, tenantIdentifier) })),
    [lessonsFromState, tenantIdentifier],
  );
  return lessons;
}
function addTenantIdentifierSearchParam(url: string, tenantIdentifier: string | undefined): any {
  if (!tenantIdentifier) {
    return url;
  }
  const newUrl = new URL(url);
  newUrl.searchParams.set('tenantIdentifier', tenantIdentifier);
  return newUrl.href;
}
