export const dashboardModuleId = '5866f309-af4e-414e-b5b0-25714d6d4c6a';

// The following statement is quoted verbatim by the Content Editor website source code in order to determine
// the order of the content entries in the Learner Dashboard.  The version in this file is considered to be
// the source of truth.  If modifying this statement, please consider also modifying the copy in:-
// https://github.com/outthinkthreats/outthink-content-editor-website/blob/develop/OutThink.ContentEditorWebsite/Server/Implementations/ContentApiContentEntriesOrderProvider.cs
export const dashboardTexts = {
  topBarText: '71ff2066-0a14-4e7d-8286-89110910784e',
  // Naming for the hero section texts: stage + 'Greeting', stage + 'Description' and stage + 'Button'
  firstVisitGreeting: 'e0cecd14-3cd1-4f26-b0ec-29cb6d2028c4',
  firstVisitDescription: '01865894-e2ce-4a45-80d2-aa9d1d8f4a99',
  firstVisitButton: '6378737d-ee5f-451b-95f5-e7eca612e43f',
  laterVisitGreeting: '11610c77-ff7b-41a5-ab86-c4a36ad47c2d',
  laterVisitDescription: '51e64a6f-8e57-4f41-9e57-96862cfba185',
  laterVisitButton: '0f573a77-9587-4b49-b809-10dcb54f6747',
  finalAssessmentGreeting: 'ad9f1e3b-a105-4d58-a682-effcffcc8f81',
  finalAssessmentDescription: '15fb47ae-c03e-4f90-871b-065e6663f302',
  finalAssessmentButton: '9c0e9324-2cf2-443f-b923-3fe1a0f9bc23',
  allCompletedGreeting: 'd284dc32-4cb9-4e5c-b001-4526672a4c18',
  allCompletedDescription: '512b6449-157a-4066-ab46-be37af40b56d',
  allCompletedButton: 'e2df8012-d8c1-4990-9892-f4985f6e7cb4',
  allCompletedLink: '81835344-a6dc-4331-be65-6667d0266220',
  failedFinalAssessmentGreeting: '0747ca39-f111-4c0d-ae8f-99bf944f2730',
  failedFinalAssessmentDescription: '5dc2fe60-9a3c-45a8-ba9e-bee0e9ccd7eb',
  failedFinalAssessmentButton: '3bffdca1-e05f-4539-bc8c-172942f9cf61',
  failedAllAttemptsGreeting: '24b9d2df-b922-4ebe-b0b3-6ac327cce13c',
  failedAllAttemptsDescription: '8ef69eae-d957-4934-a492-3e12b17653fb',
  removedFromCampaignGreeting: '08b6e8a9-d9f6-42bc-831f-310f9e83f6b7',
  removedFromCampaignDescription: 'c94ccdcd-8e5a-4bd7-be90-771d2cc09300',
  trainingExpiredGreeting: 'f866b2fc-9d3c-4db0-a578-e18bbd16ca80',
  trainingExpiredDescription: 'f3aaadf2-6a88-4c3f-bb27-eed3556e40cc',
  campaignResponseNotFoundGreeting: 'ab286d01-c93a-41e3-bfa6-da15149f3c1d',
  campaignResponseNotFoundDescription: 'e4e80b37-8319-42e0-ba28-61b361fc9e81',
  remainingLessons: 'bfb993ae-6753-414d-a1de-742e3b5d3922',
  completedLessons: '543b7fff-01e2-41d7-8412-c29015a60fea',
  notStarted: '2f50addf-eb2b-467a-85c5-4959c55c0161',
  inProgress: 'd97378e5-cbd3-4c4e-9107-b3cf71bf3ed8',
  complete: 'fa8ca22f-ae44-4e6f-aa46-6aae83b833cf',
  yourProgress: 'd2089995-99b0-4bc5-8c04-1da487a64c77',
  lessons: '09184421-a6f9-49df-8308-3e4d600d0295',
  allCompletedMessage: '775f37ca-71f6-4ede-8fce-fe554003ee3e',
  allCompletedSubMessage: '3f20f127-0f26-48b9-92ea-9684f3cf1096',
  homeButtonText: '74e29e3c-3a0f-420c-a2b7-a08743542e53',
  rewardEligibilityMessage: 'b37a728a-452b-4cf9-a425-327147c5fe46',
  rewardPageButtonText: '69bda243-e1f5-4dff-bba8-c6c6a4b9eacd',
  spinToWinSpinText: '0879bae4-88dc-47c3-80c4-d228a4c64688',
  rewardWinHeadingText: '7bcaf580-ed7d-4421-b7db-0a00b3749fc3',
  rewardWinText: '6081b24d-5b28-4de2-adaf-2939dea7cbbb',
  rewardLoseText: 'e4d973f8-4bb3-45d2-a9b5-df4a025121de',
  spinToWinHomeButtonText: 'a8241ce1-225a-47fa-95dc-925b1164dd34',
  claimPrizeMessage: '5a016753-9485-4df7-99a6-c8fa17d0f75c',
};

export const userDashboardTexts = {
  userDashboardLoginTitle: '04916254-d5b6-4554-a5f3-edf80c9992b3',
  userDashboardLoginEmailInfoLabel: 'af628a6e-faad-4403-8ff2-ddd64aa4315e',
  userDashboardLoginButton: '27f10103-1451-462b-826a-4b0729d03b5c',
  userDashboardLoginMethodEmailButton: 'e3211221-c3ea-4d7d-8ec1-47f283b35e46',
  userDashboardLoginMethodCustomIdButton: '168ab711-877c-43e0-b17c-50ea32fab1c3',
  userDashboardLoginSeparator: 'd20b1377-58d9-4c4f-b114-582ce50bb52a',
  userDashboardLoginDescription: 'a406a5c1-6574-4a74-9ffa-1fb66781038b',
  otpPopupHeader: 'bc3432da-a8d8-4e5f-9efe-8de0a7e94b9c',
  otpPopupDescription1: '45dd3946-4490-424b-aadf-ef8f6c3e006e',
  otpPopupDescription2: 'ccbbed22-808f-4097-acc2-6d14bc845e55',
  otpPopupCounter: 'b0b90158-c743-4780-b557-084f153c838d',
  otpPopupResend: '2e0cecb9-8bb3-4f8b-b2aa-84e770b1390f',
  otpPopupSubmitButton: 'b6a71a5b-0d64-4e70-974c-a8506bc17e2b',
  cancel: 'd38fb0b5-cf24-4955-9d93-aedc243aa087',
  confirmationPopupHeader: '4bcc2494-06de-4b32-a2b8-2aeeabbd38d0',
  confirmationPopupDescription1: '851bdf2b-987c-47f9-9f3a-908a5b843da0',
  confirmationPopupDescription2: '89a016df-5670-494b-ad14-659410abebf8',
  confirmationPopupConfirmButton: '43bbf032-3830-412e-95d6-ae1d8b4c90ad',
  userDashboardMyCourses: '9193d602-cf0a-47eb-ba00-03ea02727112',
  userDashboardTitle: 'c152be35-e343-4a96-bed9-c65c944ffa24',
  userDashboardDescription: '88ce35b4-88c3-4048-876b-da7f578a6ce1',
  userDashboardLogout: 'f2b15c1a-b594-488e-a83f-0f918f923acf',
  userDashboardNoCampaignsAvailable: 'ac0d997c-c458-4002-afca-1cbe696ba04a',
  userDashboardStartFirstLesson: 'ea6e1aa8-4f01-46e5-94c9-46cbe9ef6bf5',
  userDashboardContinueTraining: '690803db-58f7-4abc-8d29-402a332f6c7e',

  userDashboardTotalTimeSpent: '73485471-61e4-4914-8d6e-fd521eb7233c',
  userDashboardKnowledge: '2a578199-0efa-4be8-96c4-e744da9ca610',
  userDashboardCompletedOnTime: '3f65b977-46ae-4ace-8287-89cfbbf218ad',
};

export const dashboardGenericTexts = ['durationMinutes', 'langPopupMessage'];

export const genTextsContentEntryInfo = {
  moduleId: 'bb642722-8427-4869-8610-be533dde4417',
  contentEntryId: '279d0535-7b60-44b4-bc1f-94214534ff84',
};
