import { call } from 'redux-saga/effects';
import { CampaignInitializationParameters, StandardCampaignInitializationParameters } from 'redux-lib/actions/params';
import { SagaDelegatedYieldReturnType } from 'lib/redux-saga/SagaDelegatedYieldReturnType';
import { SCORMFuncs } from 'lib/SCORM/SCORMFuncs';
import { getOutthinkLearnerId } from './SCORM/getOutthinkLearnerId';
import { ErrorWithData } from 'lib/errors/ErrorWithData';
import { trackException } from 'tracking';

export function* getStandardCampaignInitializationParameters(
  initParams: CampaignInitializationParameters,
  scormFuncs?: SCORMFuncs,
): SagaDelegatedYieldReturnType<StandardCampaignInitializationParameters> {
  switch (initParams.type) {
    case 'standardCampaignInitializationParameters': {
      return initParams;
    }
    case 'scormCampaignInitializationParameters': {
      if (!scormFuncs) {
        throw Error('scormCampaignInitializationParameters require a scormFuncs object');
      }

      const lmsData = yield* getLMSData(scormFuncs);
      const studentId = lmsData.studentId;
      if (typeof studentId !== 'string' || studentId.length === 0) {
        throw new ErrorWithData({ message: 'cmi.core.student_id is null or empty', isMessagePublic: true });
      }
      const tenantId = new URL(window.location.href).searchParams.get('tenantId') ?? undefined;
      if (!tenantId) {
        throw new ErrorWithData({ message: 'tenant ID was not supplied', isMessagePublic: true });
      }
      const outthinkUserId = yield* getOutthinkLearnerId({
        studentId,
        tenantId,
        campaignId: initParams.campaignId,
      });
      return {
        type: 'standardCampaignInitializationParameters',
        campaignId: initParams.campaignId,
        userId: outthinkUserId,
      };
    }

    default: {
      throw Error(`cannot initialize with initParams of type ${(initParams as any).type}`);
    }
  }
}

function* getLMSData(scormFuncs: SCORMFuncs): SagaDelegatedYieldReturnType<{
  studentId: string;
  wrapperHost: string;
}> {
  try {
    const lmsData = yield call(() => scormFuncs.getLMSData());
    return lmsData;
  } catch (e: any) {
    const err = new ErrorWithData({
      data: e,
      message: 'an error occurred when calling scormFuncs.getLMSData',
    });
    trackException({
      errorCode: 'SCORMFUNCS_FAILURE',
      exception: { exception: err },
      addTrackingDetailsToException: true,
    });
    throw err;
  }
}
