import React, { FC, useEffect } from 'react';
import { SpinnerOverlay } from 'components/SpinnerOverlay';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux-lib/RootState';
import { ParamsActions } from 'redux-lib/actions/params';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router';
import { NotFound } from 'components/error/error-screens/NotFound';
import { Login } from './Login';
import { UserDashboard } from './UserDashboard';
import { isValidGuid } from 'lib/validation/isValidGuid';
import { DashboardNotEnabled } from 'components/error/error-screens/DashboardNotEnabled';

export const UserDashboardMain: FC<React.PropsWithChildren> = () => {
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (orgId && isValidGuid(orgId)) {
      dispatch(ParamsActions.setParams({ type: 'userDashboardInitializationParameters', organizationId: orgId }));
    } else {
      navigate('/not-found');
    }
  }, [dispatch, navigate, orgId]);

  const userId = useSelector((state: RootState) => state.userDashboard.dashboardData?.learnerId);

  const initialized = useSelector((state: RootState) => state.initialization.initialized);
  const dashboardEnabled = useSelector((state: RootState) => state.userDashboard.dashboardConfig?.dashboardEnabled);

  if (!initialized) {
    return <SpinnerOverlay />;
  }

  if (!dashboardEnabled) {
    return <DashboardNotEnabled />;
  }

  return (
    <Routes>
      <Route index element={userId ? <UserDashboard /> : <Navigate to="login" replace />} />
      <Route path="login" element={userId ? <Navigate to=".." replace /> : <Login />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
