import styled from 'styled-components';
import React, { FC } from 'react';
import { Spinner } from './Spinner';

export const SpinnerOverlay: FC<React.PropsWithChildren> = () => (
  <SpinnerOverlayContainer
    onClickCapture={(e) => {
      e.preventDefault();
      e.stopPropagation();
    }}
  >
    <Spinner size="4em" />
  </SpinnerOverlayContainer>
);
const SpinnerOverlayContainer = styled.div.attrs({ dir: 'ltr' })`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  /*! @noflip */
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background: radial-gradient(circle, #00000022, #00000033 80%, #00000044 100%);
`;
