import React, { FC } from 'react';
import { StyleSheetManager } from 'styled-components';
import { useIsRTLLayout } from 'lib/hooks/useIsRTLLayout';
import rtlPlugin from 'stylis-plugin-rtl';
import isPropValid from '@emotion/is-prop-valid';

export const WrapStyleSheetManager: FC<React.PropsWithChildren> = ({ children }) => {
  const isRTLLayout = useIsRTLLayout();

  const plugins = isRTLLayout ? [rtlPlugin] : undefined;
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp} stylisPlugins={plugins}>
      {children}
    </StyleSheetManager>
  );
};

function shouldForwardProp(propName: string, target: unknown) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}
