import { TextsActions } from 'redux-lib/actions/texts';
import { createReducer } from 'typesafe-actions';

type TextSubstitutionState = Record<string, string | string[]>;

const defaultState: TextSubstitutionState = {};

export const textSubstitutionsReducer = createReducer<TextSubstitutionState, TextsActions>(defaultState).handleAction(
  TextsActions.addSubstitutions,
  (state, { payload }) => {
    return { ...state, ...payload };
  },
);
