import axios from 'lib/axios';
import { ErrorWithData } from '../errors/ErrorWithData';
import { policyFactory } from 'lib/axios/policyFactory';

const apiRootEndpoint = import.meta.env.REACT_APP_API_ROOT_URL;

interface OtpRequestResponse {
  requestId: string;
  expiry: string;
}

export interface OtpTradeResponse {
  sessionId: string;
  expiry: string;
}

export const otpRequest = async (data: { email: string; organizationId: string }): Promise<OtpRequestResponse> => {
  if (!apiRootEndpoint) {
    throw new Error('REACT_APP_API_ROOT_URL not set');
  }
  const url = `${apiRootEndpoint}/training/lightDashboard/otp/request`;
  try {
    const response = await policyFactory.execute(
      ({ signal }) => axios.post<OtpRequestResponse>(url, data, { signal }),
      {
        noRetry: true,
        timeoutInterval: 10000,
      },
    );
    //const response = await axios.post<OtpRequestResponse>(url, data);
    return response.data;
  } catch (e: any) {
    throw new ErrorWithData({ data: e, message: getErrorMessageOtpRequest(e), isMessagePublic: true });
  }
};

const getErrorMessageOtpRequest = (error: any) => {
  const responseBody = error?.response?.data;
  switch (responseBody) {
    case 'learner_not_found':
      return 'We could not send OTP code for the selected user. Please contact your support manager. (learner_not_found)';
    case 'dashboard_not_enabled':
      return 'Dashboard is not enabled for this organization (dashboard_not_enabled)';
    case 'invalid_login_method':
      return 'This login method is not configured for this organization (invalid_login_method)';
    default:
      return 'Something went wrong. OTP request API error';
  }
};

export const otpTrade = async (data: { requestId: string; code: string }): Promise<OtpTradeResponse> => {
  if (!apiRootEndpoint) {
    throw new Error('REACT_APP_API_ROOT_URL not set');
  }
  const url = `${apiRootEndpoint}/training/lightDashboard/otp/trade`;
  try {
    const response = await policyFactory.execute(({ signal }) => axios.post<OtpTradeResponse>(url, data, { signal }), {
      noRetry: true,
      timeoutInterval: 10000,
    });
    //const response = await axios.post<OtpTradeResponse>(url, data);
    return response.data;
  } catch (e) {
    throw new ErrorWithData({ data: e, message: getErrorMessageOtpTrade(e), isMessagePublic: true });
  }
};

const getErrorMessageOtpTrade = (error: any) => {
  const responseBody = error?.response?.data;
  switch (responseBody) {
    case 'request_expired':
      return 'Code expired. Please request a new one.';
    case 'invalid_code':
      return 'Invalid code. Please try again.';
    default:
      return 'Something went wrong. (OTP trade API error)';
  }
};
