import { fetchMaintenanceModeStatus, MaintenanceModeStatusResponse } from 'lib/api/fetchMaintenanceModeStatus';
import { ErrorWithData } from 'lib/errors/ErrorWithData';
import { call, delay } from 'redux-saga/effects';

export function* maintenanceModeStatusCheckSaga() {
  while (true) {
    const { enabled: maintenanceModeEnabled, endTime: maintenanceModeEndTime }: MaintenanceModeStatusResponse =
      yield call(() => fetchMaintenanceModeStatus());

    if (maintenanceModeEnabled) {
      const err = new ErrorWithData({
        data: { maintenanceModeEndTime: maintenanceModeEndTime ?? undefined },
        errorCode: 'MAINTENANCE_MODE',
      });
      throw err;
    }
    yield delay(30000); //check every 30s
  }
}
