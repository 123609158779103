import { FontFaceDescriptor } from './FontFaceDescriptor';

/**
 * Create and attach stylesheet to get fonts into page
 * then, if "preload" is true, for each FontFaceDescriptor, attach hidden spans using
 * font-family, font-style and font-weight stylings
 * to force browser to load fonts immediately instead of
 * on first-use
 * @param fontDescriptors description of fonts
 * @param preload preload fonts (otherwise, loaded on first-use)
 */
export const loadFontFaces = (fontDescriptors: FontFaceDescriptor[], preload?: boolean): void => {
  attachFontFaceCSS(fontDescriptors);
  if (preload) {
    forcePreload(fontDescriptors);
  }
};

const attachFontFaceCSS = (fontDescriptors: FontFaceDescriptor[]): void => {
  const fontFaceCSS = fontDescriptors.map(createFontFaceCSSRule).join('\n');
  const styleElement = document.createElement('style');
  styleElement.textContent = fontFaceCSS;
  document.head.appendChild(styleElement);
};

const forcePreload = (fontDescriptors: FontFaceDescriptor[]): void => {
  const div = createOffscreenDiv();
  const spans = fontDescriptors.map(createSpanWithFontStyle);
  spans.forEach((span) => div.appendChild(span));
  document.body.appendChild(div);
};

const createFontFaceCSSRule = (fd: FontFaceDescriptor): string => `
    @font-face {
        font-family: '${fd.family}';
        src: ${fd.sources.map((s) => `url(${s.src}) format('${s.format}')`).join(',\n')};
        font-weight: ${fd.weight};
        font-style: ${fd.style};
    }
`;

const createOffscreenDiv = (): HTMLDivElement => {
  const div = document.createElement('div');
  div.setAttribute('style', 'position:absolute; top:-1000px; left:-1000px;');
  return div;
};

const createSpanWithFontStyle = (fd: FontFaceDescriptor): HTMLSpanElement => {
  const span = document.createElement('span');
  span.setAttribute('style', `font-family:'${fd.family}'; font-style:${fd.style}; font-weight:${fd.weight};`);
  return span;
};
