import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ModulesCompleteIllu } from 'assets/images/illu-modulescomplete.svg';
import { useTexts } from 'lib/hooks/useTexts';
import { Link, useParams } from 'react-router-dom';
import { spinToWinCampaignSelector } from 'redux-lib/selectors/spinToWinCampaignSelector';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import Button from './styled/Button';

const sanitizeHtmlString = (html: string): string => {
  const sanitized = DOMPurify.sanitize(html, {
    ALLOWED_TAGS: ['a', 'strong', 'em', 'span', 'mark', 'br', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    ALLOW_DATA_ATTR: false,
    ALLOWED_ATTR: ['href', 'dir'],
    RETURN_DOM: true,
  });

  const anchors = sanitized.querySelectorAll('a');
  anchors.forEach((e) => {
    e.setAttribute('target', '_blank');
    e.setAttribute('rel', 'noopener');
  });

  return sanitized.innerHTML;
};

const AllCompletedMessage = () => {
  const { campaignId, userId } = useParams();
  const spinToWinCampaignInfo = useSelector(spinToWinCampaignSelector);

  const [allCompletedMessage, allCompletedSubMessage, rewardPageButtonText, rewardEligibilityMessage] = useTexts([
    'allCompletedMessage',
    'allCompletedSubMessage',
    'rewardPageButtonText',
    'rewardEligibilityMessage',
  ]);

  const rewardEligibilityMessageSanitized = React.useMemo(() => {
    if (rewardEligibilityMessage.length && rewardEligibilityMessage[0]) {
      return sanitizeHtmlString(rewardEligibilityMessage[0]);
    }
  }, [rewardEligibilityMessage]);

  const showSpinToWinLink = spinToWinCampaignInfo?.isSpinToWinCampaign && !spinToWinCampaignInfo?.rewardGiven;

  return (
    <Container showSpinToWinLink={!!showSpinToWinLink}>
      <ImgCont>
        <ModulesCompleteIllu />
      </ImgCont>
      <Title>{allCompletedMessage}</Title>
      <Text>{allCompletedSubMessage}</Text>
      {showSpinToWinLink && (
        <RewardEligibility>
          {rewardEligibilityMessageSanitized && (
            <div dangerouslySetInnerHTML={{ __html: rewardEligibilityMessageSanitized }}></div>
          )}
          <StyledButton as={Link} to={`/dashboard/reward/${campaignId}/${userId}`}>
            {rewardPageButtonText}
          </StyledButton>
        </RewardEligibility>
      )}
    </Container>
  );
};

const Container = styled.div<{ showSpinToWinLink: boolean }>`
  margin-top: 20px;
  padding: ${(props) => (props.showSpinToWinLink ? '20px' : '40px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #dfdfdf;
  text-align: center;

  @media screen and (max-width: 625px) {
    margin-top: 5px;
  }
`;

const ImgCont = styled.div`
  width: 150px;
`;

const Title = styled.div`
  margin-top: 10px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
  opacity: 0.6;
`;

const Text = styled.div`
  margin-top: 8px;
  line-height: 1.25;
  opacity: 0.6;
`;

const RewardEligibility = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  background-color: #3abf871a;
  & > div {
    font-size: 1.5rem;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 10px;
`;

export default AllCompletedMessage;
