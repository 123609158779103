import { dashboardGenericTexts, dashboardModuleId, genTextsContentEntryInfo } from 'constants/texts';
import { ContentEntry } from 'lib/api/apiTypes/ContentEntry';
import { isGenericTextsData, isTextBlockData } from 'lib/api/apiTypes/ContentEntryData';
import { fetchTexts } from 'lib/api/fetchTexts';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

export function* getTexts(langCode: string, texts: Record<string, string>): SagaIterator {
  const textsResp: ContentEntry[] = yield call(async () => {
    const entries = Object.values(texts);
    const textsResponse = await fetchTexts(langCode, dashboardModuleId, entries);
    return textsResponse;
  });

  const genTexts: ContentEntry[] = yield call(async () => {
    const genTextsResponse = await fetchTexts(langCode, genTextsContentEntryInfo.moduleId, [
      genTextsContentEntryInfo.contentEntryId,
    ]);
    return genTextsResponse;
  });

  const parsedTexts: Record<string, string | string[]> = Object.fromEntries(
    Object.entries(texts).map(([k, v]) => {
      const ce = textsResp.find((contentEntry: ContentEntry) => contentEntry.contentId === v);
      if (ce && isTextBlockData(ce.data)) {
        return [k, ce.data.text];
      } else {
        throw new Error(`Content entry not found ${k} ${v}`);
      }
    }),
  );

  const parsedGenTexts = genTexts[0] && isGenericTextsData(genTexts[0].data) ? genTexts[0].data.texts : undefined;

  const dbGenTexts = parsedGenTexts
    ? dashboardGenericTexts.reduce((texts, currText) => ({ ...texts, [currText]: parsedGenTexts[currText] }), {})
    : {};

  const allTexts = { ...parsedTexts, ...dbGenTexts };

  return allTexts;
}
