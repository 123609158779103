import axios from 'lib/axios';
import { ErrorWithData } from '../errors/ErrorWithData';
import { policyFactory } from 'lib/axios/policyFactory';

export interface LearnerPerformanceResponse {
  learnerId: string;
  totalTimeSpent: string | null;
  knowledgePercentile: number | null;
  completionOnTimePercentage: number | null;
}

const apiRootEndpoint = import.meta.env.REACT_APP_API_ROOT_URL;

export const fetchLearnerPerformance = async (learnerId: string): Promise<LearnerPerformanceResponse> => {
  if (!learnerId) {
    throw new Error('Learner ID must be provided');
  }
  if (!apiRootEndpoint) {
    throw new Error('REACT_APP_API_ROOT_URL not set');
  }
  const url = `${apiRootEndpoint}/training/lightDashboard/learners/${learnerId}/performance`;
  try {
    const response = await policyFactory.execute(
      ({ signal }) =>
        axios.get<LearnerPerformanceResponse>(url, {
          signal,
        }),
      { timeoutInterval: 10000, noRetry: true },
    );
    return response.data;
  } catch (error: any) {
    throw new ErrorWithData({ data: error, message: getErrorMessage(error), isMessagePublic: true });
  }
};

const getErrorMessage = (error: any) => {
  const statusCode = error?.response?.status;
  switch (statusCode) {
    case 400:
      return 'Bad Request. Please check the learner ID.';
    case 404:
      return 'Learner not found. Please check the learner ID.';
    default:
      return 'Something went wrong. (Learner Performance API error)';
  }
};
