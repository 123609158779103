import React from 'react';
import { BrowserHistoryContext } from '..';

export const useHistory = () => {
  const ctx = React.useContext(BrowserHistoryContext);
  if (ctx == null) {
    throw Error('this should not happen');
  }
  return ctx.history;
};
