import { SagaIterator } from 'redux-saga';
import { takeEvery } from 'redux-saga/effects';
import { CampaignActions } from 'redux-lib/actions/campaign';
import { DashboardDataActions } from 'redux-lib/actions/dashboardData';
import { InitializationActions } from 'redux-lib/actions/initialization';
import { TelemetryActions } from 'redux-lib/actions/telemetry';
import { TextsActions } from 'redux-lib/actions/texts';
import { ActionType, getType } from 'typesafe-actions';

export function* errorLoggerSaga(): SagaIterator {
  yield takeEvery(
    getType(InitializationActions.initializationFailure),
    function ({ payload }: ActionType<typeof InitializationActions.initializationFailure>) {
      console.error(payload);
    },
  );

  yield takeEvery(
    [
      getType(DashboardDataActions.fetchResult),
      getType(CampaignActions.fetchResult),
      getType(TextsActions.fetchResult),
      getType(TelemetryActions.sendResult),
    ],
    // eslint-disable-next-line
    function* ({
      payload,
    }:
      | ActionType<typeof DashboardDataActions.fetchResult>
      | ActionType<typeof CampaignActions.fetchResult>
      | ActionType<typeof TextsActions.fetchResult>
      | ActionType<typeof TelemetryActions.sendResult>) {
      if (payload.type === 'Error') {
        console.error(payload.error);
      }
    },
  );
}
