import { fork } from 'redux-saga/effects';
import { initializationSaga } from './initializationSaga';
import { errorLoggerSaga } from './errorLoggerSaga';
import { SagaIterator } from 'redux-saga';
import { sendTelemetrySaga } from './sendTelemetrySaga';
import { telemetryWarningSaga } from './telemetryWarningSaga';
import { textSubstitutionSaga } from './textSubstitutionSaga';
import { maintenanceModeStatusCheckSaga } from './maintenanceModeStatusCheckSaga';

const sagas = [
  initializationSaga,
  errorLoggerSaga,
  sendTelemetrySaga,
  telemetryWarningSaga,
  textSubstitutionSaga,
  maintenanceModeStatusCheckSaga,
];

export function* getRootSaga(): SagaIterator {
  for (const saga of sagas) {
    yield fork(saga);
  }
}
