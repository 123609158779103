import { extractErrorCode } from 'lib/errors/ErrorWithData';
import { FC } from 'react';
import { CampaignExpired } from './error-screens/CampaignExpired';
import { DefaultError } from './error-screens/DefaultError';
import { MaintenanceMode } from './error-screens/MaintenanceMode';

export const ShowError: FC<React.PropsWithChildren<{ error: any }>> = ({ error }) => {
  const errorCode = extractErrorCode(error);

  switch (errorCode) {
    case 'CAMPAIGN_EXPIRED':
      return <CampaignExpired />;
    case 'MAINTENANCE_MODE': {
      const maintenanceModeEndTime: string | undefined = error.data.maintenanceModeEndTime;
      return <MaintenanceMode endTime={maintenanceModeEndTime} />;
    }
    default:
      return <DefaultError error={error} />;
  }
};
