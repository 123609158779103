import styled from 'styled-components';

const Pill = styled.div<{ $color?: string; bgColor?: string }>`
  display: inline-block;
  padding: 5px 16px;
  color: ${(props) => props.$color || props.theme.colors.text};
  background-color: ${(props) => props.bgColor || 'transparent'};
  border-radius: 15px;
  font-size: 14px;

  & > span {
    display: inline-block;
    padding: 1px 9px 3px;
    margin-left: 8px;
    color: #fff;
    background-color: ${(props) => props.$color || props.theme.colors.text};
    border-radius: 50%;
  }

  &:not(:last-child) {
    margin-right: 12px;
  }

  @media screen and (max-width: 400px) {
    font-size: 12px;

    &:not(:last-child) {
      margin-right: 0;
    }
  }
`;

export default Pill;
