import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { CampaignResponse } from 'lib/api/apiTypes/CampaignResponse';
import { fetchCampaignData } from 'lib/api/fetchApiData';

export function* getCampaignData(campaignId: string, userId: string): SagaIterator {
  const campaignData: CampaignResponse = yield call(async () => {
    const apiResponse = await fetchCampaignData(campaignId, userId);
    return apiResponse;
  });

  return campaignData;
}
