// import { CampaignInitializationParameters } from 'redux/actions/params';
// import { SCORMSessionData } from './SCORMSessionData';
// import { SCORMParamsLocalStorageKey } from './SCORMParamsLocalStorageKey';

/**
 * gather scormOrigin, scormSessionId from URL parameters (set by SCORM wrapper)
 */
export function getSCORMParameters(): SCORMParameters | undefined {
  const scormOrigin = new URL(window.location.href).searchParams.get('scormOrigin');
  const scormSessionId = new URL(window.location.href).searchParams.get('scormSessionId');
  return scormOrigin && scormSessionId ? { scormOrigin, scormSessionId } : undefined;
}
export interface SCORMParameters {
  scormOrigin: string;
  scormSessionId: string;
}
