import { ParamsActions } from 'redux-lib/actions/params';
import { createReducer } from 'typesafe-actions';

export interface UserState {
  id: string | null;
}

const defaultState: UserState = { id: null };

export const userReducer = createReducer<UserState, ParamsActions>(defaultState).handleAction(
  ParamsActions.setUserId,
  (state, { payload }) => ({ ...state, id: payload }),
);
