import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux-lib/RootState';
import { getThemeSettings } from './getThemeSettings';
import { ThemeSettings } from './ThemeSettings';

export function useThemeSettings(): ThemeSettings {
  const themeConfig = useSelector((state: RootState) => state.initialization.themeConfig);
  const themeSettings = React.useMemo(
    () => getThemeSettings(themeConfig?.dashboard, themeConfig?.training),
    [themeConfig],
  );
  return themeSettings;
}
