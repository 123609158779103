import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ReactComponent as _NetworkIcon } from 'assets/icons/wireless-icon.svg';
import { useIndicatorInfo } from './useIndicatorInfo';
import { transparentize } from 'polished';
import { useClickOutside } from 'lib/hooks/useClickOutside';

export const NetworkStatus: React.FC = () => {
  const [hovering, setHovering] = React.useState(false);
  const { color, flashRateMS, message, indicatorState, telemetryStatus } = useIndicatorInfo();
  const [clicked, setClicked] = React.useState(false);
  const clickOutsideRef = useClickOutside<HTMLDivElement>(() => {
    setHovering(false);
    setClicked(false);
  });
  const messages = React.useMemo(
    () => [
      ...(message ? [message] : []),
      ...(telemetryStatus === 'retrying'
        ? ['Cannot send telemetry. Retrying...']
        : telemetryStatus === 'failed'
          ? ['Failed to send telemetry']
          : []),
    ],
    [message, telemetryStatus],
  );
  return (
    <Indicator
      ref={clickOutsideRef}
      isOpen={hovering}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => !clicked && setHovering(false)}
      onClick={() => {
        setClicked((h) => !h);
        setHovering(!clicked);
      }}
      active={indicatorState !== 'ok'}
    >
      <Top hovering={hovering} style={{ gridTemplateColumns: `${hovering ? '1fr' : '0fr'} 31px` }}>
        <Title>Network warning</Title>
        <NetworkIcon $iconColor={color} $flashRateMS={flashRateMS} />
      </Top>
      <Details style={{ gridTemplateRows: hovering ? '1fr' : '0fr', gridTemplateColumns: hovering ? '1fr' : '0fr' }}>
        <Outer>
          <Inner>
            {messages.map((m, i) => (
              <div key={`${m}${i}`}>{m}</div>
            ))}
          </Inner>
        </Outer>
      </Details>
    </Indicator>
  );
};

const flashKf = keyframes`
  0% {
    opacity:0;
  }
  50% {
    opacity:1;
  }
  100% {
    opacity:0;
  }

`;

const Title = styled.span`
  overflow: hidden;
  white-space: nowrap;
  min-width: 0;
  font-weight: bold;
  font-size: 22px;
`;

const Top = styled.div<{ hovering: boolean }>`
  display: grid;
  width: 100%;
  transition:
    grid-template-columns 500ms ease-in-out,
    border-bottom 500ms ease-in-out,
    padding-bottom 500ms ease-in-out;
  align-items: center;
  border-bottom: ${(props) => (props.hovering ? '2px solid #666' : '0px solid transparent')};
  padding-bottom: ${(props) => (props.hovering ? '1em' : '0')};
`;

const NetworkIcon = styled(_NetworkIcon)<{ $iconColor: string; $flashRateMS?: number }>`
  width: 31px;
  height: 22px;
  path {
    fill: ${(props) => props.$iconColor};
    transition: fill linear 500ms;
  }
  ${(props) =>
    props.$flashRateMS != null &&
    css`
      animation: ${flashKf} ${props.$flashRateMS}ms infinite ease-in-out;
    `}
  filter: 
        drop-shadow(0 0 1px ${transparentize(0.8, '#fff')})
        drop-shadow(0 0 2px ${transparentize(0.8, '#fff')})
        drop-shadow(0 0 3px ${(props) => transparentize(0.8, props.$iconColor)})
        drop-shadow(0 0 4px ${(props) => transparentize(0.8, props.$iconColor)})
        drop-shadow(0 0 5px ${(props) => transparentize(0.8, props.$iconColor)});
`;

const Indicator = styled.div<{ active: boolean; isOpen: boolean }>`
  font-family: 'Segoe UI', Frutiger, 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
  pointer-events: ${(props) => (props.active ? 'auto' : 'none')};
  opacity: ${(props) => (props.active ? 1 : 0)};
  position: fixed;
  z-index: 50000;
  top: 1em;
  left: 1em;

  background-color: ${(props) => (props.isOpen ? '#444444dd' : '#44444488')};
  padding: ${(props) => (props.isOpen ? '1' : '0.5')}em;
  /* &:hover {
        background-color: #444444dd;
        padding: 1em;
    } */
  transition:
    background-color linear 500ms,
    padding linear 500ms,
    opacity linear 1000ms;
  color: #ccc;

  border-radius: 5px;
  user-select: none;
`;

const Outer = styled.div`
  overflow: hidden;
`;

const Inner = styled.div`
  width: 13.5em;
  padding-top: 0.5em;
`;

const Details = styled.div`
  text-align: left;
  display: grid;
  transition:
    grid-template-rows 500ms ease-in-out,
    grid-template-columns 500ms ease-in-out;
  font-size: 20px;
  color: #ccc;
`;
