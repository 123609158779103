import { DashboardState } from 'components/types/DashboardState';
import {
  isCampaignNotFound,
  isOngoingCampaign,
  isNotInCampaign,
  isPassRateRequiredCampaign,
  isTrainingExpiredCampaign,
} from 'lib/api/apiTypes/CampaignResponse';
import { Lesson } from 'lib/api/apiTypes/Lesson';
import { RootState } from 'redux-lib/RootState';
import { addTenantIdentifier } from './lib/addTenantIdentifier';

export type ProgressInfo = {
  totalNumber: number;
  completedNumber: number;
  remainingNumber: number;
  progressPercentage: number;
  dashboardState: DashboardState;
  buttonUrl: string | null | undefined;
  certificateInfo: CertificateInfo | undefined;
};

export const progressSelector = (state: RootState): ProgressInfo => {
  const { lessons, campaignResponse, finalAssessment } = state.campaign;
  const failedFinalAssessment = isPassRateRequiredCampaign(campaignResponse)
    ? campaignResponse.failedFinalAssessment
    : false;
  const failedAllAttempts = isPassRateRequiredCampaign(campaignResponse) ? campaignResponse.failedAllAttempts : false;
  const removedFromCampaign = isNotInCampaign(campaignResponse);
  const campaignResponseNotFound = isCampaignNotFound(campaignResponse);
  const trainingExpired = isTrainingExpiredCampaign(campaignResponse);
  const totalNumber = lessons.length;
  const completedNumber = lessons.filter((l) => l.status === 'Completed').length;
  const remainingNumber = totalNumber - completedNumber;
  const progressPercentage = totalNumber > 0 ? Math.round((completedNumber / totalNumber) * 100) : 0;
  const dashboardState: DashboardState = campaignResponseNotFound
    ? 'campaignResponseNotFound'
    : removedFromCampaign
      ? 'removedFromCampaign'
      : trainingExpired
        ? 'trainingExpired'
        : completedNumber === 0
          ? 'firstVisit'
          : totalNumber !== completedNumber
            ? 'laterVisit'
            : failedAllAttempts
              ? 'failedAllAttempts'
              : failedFinalAssessment
                ? 'failedFinalAssessment'
                : finalAssessment && finalAssessment.status !== 'Completed'
                  ? 'finalAssessment'
                  : 'allCompleted';
  const certificateInfo = getCertificateInfo(state, dashboardState);
  //const certificateDownloadUrl = certificateInfo?.isCertificateEnabled ? certificateInfo.url : undefined;

  const buttonUrl = getButtonUrl(dashboardState, lessons, finalAssessment);

  return {
    totalNumber,
    completedNumber,
    remainingNumber,
    progressPercentage,
    dashboardState,
    buttonUrl,
    certificateInfo,
  };
};

export type CertificateInfo = { waitingForCertificate: boolean; url?: string };

function getCertificateInfo(state: RootState, dashboardState: DashboardState): CertificateInfo | undefined {
  if (dashboardState !== 'allCompleted' || !isOngoingCampaign(state.campaign.campaignResponse)) {
    return undefined;
  }
  if (!state.campaign.campaignResponse.certificateEnabled) {
    return { waitingForCertificate: false };
  }
  // we're on the final screen of a completed campaign and certificateEnabled is true
  return {
    waitingForCertificate: state.campaign.certificateResponse?.certificateDownloadUrl == null,
    url: state.campaign.certificateResponse?.certificateDownloadUrl ?? undefined,
  };
}

const getButtonUrl = (
  dashboardState: DashboardState,
  lessons: Lesson[],
  finalAssessment?: Lesson,
): string | null | undefined => {
  switch (dashboardState) {
    case 'firstVisit':
    case 'laterVisit': {
      const urlString = lessons.filter((m) => m.status !== 'Completed')[0]?.url;
      return addTenantIdentifier(urlString);
    }
    case 'finalAssessment':
    case 'failedFinalAssessment':
      return addTenantIdentifier(finalAssessment?.url) || '#';
    case 'failedAllAttempts':
    case 'removedFromCampaign':
    case 'allCompleted':
      return null;
    default:
      return null;
  }
};
