import axios from 'lib/axios';
import { ErrorWithData } from '../errors/ErrorWithData';
import { UserDashboardConfig } from './apiTypes/UserDashboardConfig';
import { policyFactory } from 'lib/axios/policyFactory';

const apiRootEndpoint = import.meta.env.REACT_APP_API_ROOT_URL;

export const fetchUserDashboardConfig = async (organizationId: string): Promise<UserDashboardConfig> => {
  if (!apiRootEndpoint) {
    throw new Error('REACT_APP_API_ROOT_URL not set');
  }
  const url = `${apiRootEndpoint}/training/lightDashboard/organizations/${organizationId}`;
  try {
    const response = await policyFactory.execute(
      ({ signal }) =>
        axios.get<UserDashboardConfig>(url, {
          signal,
        }),
      { timeoutInterval: 10000 },
    );
    //const response = await axios.get<UserDashboardConfig>(url);
    return response.data;
  } catch (e) {
    throw new ErrorWithData({ data: e, message: 'User Dashboard API error' });
  }
};
