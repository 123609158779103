import { CampaignResponse, isCampaignNotFound } from 'lib/api/apiTypes/CampaignResponse';
import { DashboardResponse } from 'lib/api/apiTypes/DashboardResponse';
import { prepareErrorForSerialization } from 'lib/errors/prepareErrorForSerialization';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { CampaignActions } from 'redux-lib/actions/campaign';
import { DashboardDataActions } from 'redux-lib/actions/dashboardData';
import { TelemetryActions } from 'redux-lib/actions/telemetry';
import { TextsActions } from 'redux-lib/actions/texts';
import { RootState } from 'redux-lib/RootState';
import { trackException } from 'tracking';
import { ActionType, getType } from 'typesafe-actions';
import { getDashboardData } from './getDashboardDataSaga';
import { getTexts } from './getTextsSaga';
import { SCORMParameters } from './initializationSaga/SCORM/getSCORMParameters';
import { dashboardTexts } from 'constants/texts';

export function* languageChangeSaga(
  isReturningLearner: boolean,
  scormParameters: SCORMParameters | undefined,
): SagaIterator {
  yield takeEvery(
    getType(CampaignActions.languageSelected),
    function* ({ payload: langCode }: ActionType<typeof CampaignActions.languageSelected>) {
      const campaignId: string | null = yield select((state: RootState) => state.campaign.id);
      const userId: string | null = yield select((state: RootState) => state.user.id);
      const campaignResponse: CampaignResponse | undefined = yield select(
        (state: RootState) => state.campaign.campaignResponse,
      );
      const tasks = [
        put(TelemetryActions.send({ type: 'LanguageSelected', data: { langCode } })),
        call(function* () {
          let textResults: Record<string, string | string[]> | undefined;
          try {
            textResults = yield call(getTexts, langCode, dashboardTexts);
          } catch (err) {
            trackException({
              exception: { exception: err as any },
              errorCode: 'FETCH_TEXTS_FAILED',
              addTrackingDetailsToException: true,
            });
            yield put(
              TextsActions.fetchResult({
                type: 'Error',
                error: prepareErrorForSerialization(err),
              }),
            );
            return;
          }
          if (textResults) {
            yield put(TextsActions.fetchResult({ type: 'OK', value: textResults }));
          }
        }),
      ];
      // if the campaignResponse came back 404 (i.e. isCampaignNotFound came back true),
      // so will this. no point in even requesting it.
      if (campaignId && userId && !isCampaignNotFound(campaignResponse)) {
        tasks.push(
          call(function* () {
            let dashboardResponse: DashboardResponse | undefined;

            try {
              dashboardResponse = yield call(() =>
                getDashboardData(campaignId, userId, langCode, isReturningLearner, scormParameters),
              );
            } catch (err) {
              trackException({
                exception: { exception: err as any },
                errorCode: 'FETCH_DASHBOARD_DATA_FAILED',
                addTrackingDetailsToException: true,
              });

              yield put(
                DashboardDataActions.fetchResult({
                  type: 'Error',
                  error: prepareErrorForSerialization(err),
                }),
              );
            }
            if (dashboardResponse) {
              yield put(DashboardDataActions.fetchResult({ type: 'OK', value: dashboardResponse }));
            }
          }),
        );
      } else {
        console.error('Parameter missing');
      }

      yield all(tasks);

      yield put(CampaignActions.languageChangeSuccess());
    },
  );
}
