import { put, take, call, fork, select, delay } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { ActionType, getType } from 'typesafe-actions';
import { TextsActions } from 'redux-lib/actions/texts';
import { InitializationActions } from '../../actions/initialization';
import { getTexts } from '../getTextsSaga';
import { ParamsActions } from 'redux-lib/actions/params';
import { getLanguages } from '../getLanguagesSaga';
import { TelemetryActions } from 'redux-lib/actions/telemetry';
import { isValidGuid } from 'lib/validation/isValidGuid';
import { getCampaignData } from '../getCampaignDataSaga';
import { getCertificateData } from '../getCertificateData';
import {
  CampaignResponse,
  isCampaignNotFound,
  isExpiredCampaign,
  isOngoingCampaign,
  isNotInCampaign,
} from 'lib/api/apiTypes/CampaignResponse';
import { getDashboardData } from '../getDashboardDataSaga';
import { DashboardResponse } from 'lib/api/apiTypes/DashboardResponse';
import { prepareErrorForSerialization } from 'lib/errors/prepareErrorForSerialization';
import { ErrorWithData } from 'lib/errors/ErrorWithData';
import { getSCORMFuncs } from 'lib/SCORM/getSCORMFuncs';
import { SCORMFuncs } from 'lib/SCORM/SCORMFuncs';
import { getSCORMParameters } from './SCORM/getSCORMParameters';
import { getStandardCampaignInitializationParameters } from './getStandardCampaignInitializationParameters';
import { syncSCORMState } from './SCORM/syncSCORMState';
import { languageChangeSaga } from '../languageChangeSaga';
import { trackException } from 'tracking';
import { CampaignActions } from 'redux-lib/actions/campaign';
import { DashboardDataActions } from 'redux-lib/actions/dashboardData';
import { getScormCompletionSignallingHandler } from './getScormCompletionSignallingHandler';
import { ProgressInfo, progressSelector } from 'redux-lib/selectors/progressSelector';
import { userDashboardInitializationSaga } from './userDashboardInitializationSaga';
import { dashboardTexts } from 'constants/texts';
import { isValidLMSId } from 'lib/validation/isValidLMSId';
import { getThemeConfig } from './getThemeConfig';
import { addTenantIdentifier } from 'redux-lib/selectors/lib/addTenantIdentifier';

export const FINAL_ASSESSMENT_MODULE_ID = 'e6c8372f-2fe1-4e7c-b623-70ec7dd63b38';

export function* initializationSaga(): SagaIterator {
  const { payload: initializationParameters }: ActionType<typeof ParamsActions.setParams> = yield take(
    getType(ParamsActions.setParams),
  );

  if (initializationParameters.type === 'userDashboardInitializationParameters') {
    yield call(userDashboardInitializationSaga, initializationParameters.organizationId);
    return;
  }

  const scormParameters = getSCORMParameters();
  const scormFuncs: SCORMFuncs | undefined = scormParameters
    ? yield call(() => getSCORMFuncs(scormParameters))
    : undefined;

  const handleScormCompletionSignalling = getScormCompletionSignallingHandler(scormFuncs);

  let campaignId: string;
  let userId: string;
  try {
    const { campaignId: cid, userId: uid } = yield* getStandardCampaignInitializationParameters(
      initializationParameters,
      scormFuncs,
    );
    campaignId = cid;
    userId = uid;
  } catch (e: any) {
    trackException({
      exception: { exception: e as any },
      errorCode: `GET_INITIALIZATION_PARAMETERS_FAILED`,
      addTrackingDetailsToException: true,
    });

    yield put(InitializationActions.initializationFailure(prepareErrorForSerialization(e)));
    return;
  }

  if (!isValidGuid(campaignId)) {
    const err = new Error('Invalid GUID supplied for campaignId');
    trackException({
      exception: { exception: err },
      errorCode: 'INVALID_CAMPAIGN_ID',
      addTrackingDetailsToException: true,
    });

    yield put(InitializationActions.initializationFailure(prepareErrorForSerialization(err)));
    return;
  }

  if (!(isValidGuid(userId) || isValidLMSId(userId))) {
    const err = new Error('Invalid GUID or LMS ID supplied for userId');
    trackException({
      exception: { exception: err },
      errorCode: 'INVALID_USER_ID',
      addTrackingDetailsToException: true,
    });

    yield put(InitializationActions.initializationFailure(prepareErrorForSerialization(err)));
    return;
  }

  yield put(ParamsActions.setUserId(userId));
  const isReturningLearner = new URL(window.location.href).searchParams.get('returningLearner') === 'true';
  yield put(CampaignActions.isReturningLearner(isReturningLearner));

  yield fork(() => languageChangeSaga(isReturningLearner, scormParameters));
  let campaignResponse: CampaignResponse | undefined;

  try {
    campaignResponse = yield call(getCampaignData, campaignId, userId);
  } catch (err) {
    const e = new ErrorWithData({
      data: err,
      message: 'Could not get campaign API response',
      isMessagePublic: true,
    });
    trackException({
      exception: { exception: e },
      errorCode: 'FETCH_CAMPAIGN_DATA_FAILED',
      addTrackingDetailsToException: true,
    });

    yield put(
      CampaignActions.fetchResult({
        type: 'Error',
        error: prepareErrorForSerialization(e),
      }),
    );
    yield put(InitializationActions.initializationFailure(e));
    return;
  }

  if (!campaignResponse) {
    const e = new ErrorWithData({
      message: 'campaignResponse is unexpectedly null',
      isMessagePublic: true,
    });
    trackException({
      exception: { exception: e },
      errorCode: 'CAMPAIGN_RESPONSE_IS_NULL',
      addTrackingDetailsToException: true,
    });
    yield put(InitializationActions.initializationFailure(e));
    return;
  }

  yield put(CampaignActions.fetchResult({ type: 'OK', value: campaignResponse }));

  yield call(() => getThemeConfig({ campaignId, userId }));

  const cr = campaignResponse;
  yield call(() => handleScormCompletionSignalling(cr));

  if (isExpiredCampaign(campaignResponse)) {
    const err = new ErrorWithData({
      isMessagePublic: true,
      message: 'This campaign can no longer be completed, as it has now expired.',
      data: campaignResponse,
      errorCode: 'CAMPAIGN_EXPIRED',
    });
    trackException({
      exception: { exception: err },
      errorCode: 'CAMPAIGN_EXPIRED',
      addTrackingDetailsToException: true,
    });
    yield put(InitializationActions.initializationFailure(prepareErrorForSerialization(err)));
    return;
  }

  const language = campaignResponse.contentLanguage ?? 'en-GB';

  let dashboardResponse: DashboardResponse | undefined;

  if (!(isNotInCampaign(campaignResponse) || isCampaignNotFound(campaignResponse))) {
    try {
      dashboardResponse = yield call(() =>
        getDashboardData(campaignId, userId, language, isReturningLearner, scormParameters),
      );
    } catch (err) {
      trackException({
        exception: { exception: err as any },
        errorCode: 'FETCH_DASHBOARD_DATA_FAILED',
        addTrackingDetailsToException: true,
      });

      yield put(
        DashboardDataActions.fetchResult({
          type: 'Error',
          error: prepareErrorForSerialization(err),
        }),
      );
    }

    if (!dashboardResponse) {
      const err = new ErrorWithData({
        isMessagePublic: true,
        message: 'dashboardResponse is unexpectedly null',
      });
      trackException({
        exception: { exception: err },
        errorCode: 'DASHBOARD_RESPONSE_IS_NULL',
        addTrackingDetailsToException: true,
      });
      yield put(InitializationActions.initializationFailure(prepareErrorForSerialization(err)));
      return;
    } else {
      yield put(DashboardDataActions.fetchResult({ type: 'OK', value: dashboardResponse }));
    }

    if (scormFuncs) {
      yield* syncSCORMState(dashboardResponse, scormFuncs);
    }
  }

  yield put(TelemetryActions.startTelemetry());

  yield put(TelemetryActions.send({ type: 'DashboardVisited' }));

  if (
    isOngoingCampaign(campaignResponse) &&
    (campaignResponse.currentAssignment?.assignmentType === 'initialAssessment' ||
      campaignResponse.nextAssignment?.assignmentType === 'initialAssessment')
  ) {
    yield take(getType(TelemetryActions.sendResult));
    const initialAssessment = dashboardResponse?.modules.find((m) => m.name === 'Initial Assessment');
    if (!initialAssessment) {
      const err = new ErrorWithData({ message: 'Initial Assessment not found', isMessagePublic: true });
      trackException({
        errorCode: 'INITIAL_ASSESSMENT_UNAVAILABLE',
        exception: { exception: err },
        addTrackingDetailsToException: true,
      });
      yield put(InitializationActions.initializationFailure(prepareErrorForSerialization(err)));
      return;
    }
    if (initialAssessment.status !== 'Completed') {
      window.location.replace(addTenantIdentifier(initialAssessment.url));
      return;
    }
  }

  yield fork(getLanguages);

  let textResults: Record<string, string | string[]> | undefined;
  try {
    textResults = yield call(getTexts, language, dashboardTexts);
  } catch (err) {
    trackException({
      exception: { exception: err as any },
      errorCode: 'FETCH_TEXTS_FAILED',
      addTrackingDetailsToException: true,
    });
    yield put(
      TextsActions.fetchResult({
        type: 'Error',
        error: prepareErrorForSerialization(err),
      }),
    );
  }

  if (!textResults) {
    const err = new ErrorWithData({ message: 'textResults is unexpectedly null', isMessagePublic: true });
    trackException({
      exception: { exception: err },
      errorCode: 'FETCH_TEXTS_IS_NULL',
      addTrackingDetailsToException: true,
    });

    yield put(InitializationActions.initializationFailure(prepareErrorForSerialization(err)));
    return;
  } else {
    yield put(TextsActions.fetchResult({ type: 'OK', value: textResults }));
  }

  if (isReturningLearner) {
    const url = new URL(window.location.href);
    url.searchParams.delete('returningLearner');
    window.history.pushState({}, document.title, url.href);
  }
  yield* pollForCertificate(campaignId, userId);

  yield put(InitializationActions.initializationSuccess());
}
function* pollForCertificate(campaignId: string, userId: string) {
  yield fork(function* (): SagaIterator {
    for (;;) {
      const { certificateInfo }: ProgressInfo = yield select(progressSelector);
      if (!certificateInfo) {
        return;
      }
      if (!certificateInfo.waitingForCertificate) {
        return;
      }
      yield delay(5000);
      const certificateResponse = yield call(getCertificateData, campaignId, userId);
      yield put(CampaignActions.certificateFetchResult({ type: 'OK', value: certificateResponse }));
    }
  });
}
