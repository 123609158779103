import { TelemetryEvent } from 'lib/telemetry/types/TelemetryEvent';
import { TelemetryOptions } from 'lib/telemetry/types/TelemetryOptions';
import { AxiosResponse } from 'axios';
import axios from 'lib/axios';
import { policyFactory } from 'lib/axios/policyFactory';
import { telemetryEndpoint } from './telemetryEndpoint';

export const getPostTelemetry =
  (opts: TelemetryOptions) =>
  (event: TelemetryEvent): Promise<AxiosResponse<any>> =>
    telemetryEndpoint
      ? policyFactory.execute(
          ({ signal }) =>
            axios.post(
              telemetryEndpoint,
              {
                ...event,
                ...opts,
              },
              { signal },
            ),

          {
            timeoutInterval: 5000,
            noRetry: true,
            dontSignalFailure: true,
          },
        )
      : Promise.reject(Error('no telemetry endpoint'));
