import { ButtonHTMLAttributes, FC } from 'react';
import useMeasure from 'react-use-measure';
import styled, { useTheme } from 'styled-components';
import { DestyledButton } from './DestyledButton';

interface SecondaryButtonProps {
  borderWidth?: number;
  borderColor?: string;
  backgroundColor?: string;
  cutCornerSizePx?: number;
}

export const SecondaryButton: FC<
  React.PropsWithChildren<SecondaryButtonProps & ButtonHTMLAttributes<HTMLButtonElement>>
> = ({ borderWidth, borderColor, backgroundColor, cutCornerSizePx, className, children, ...buttonProps }) => {
  return (
    <SecondaryButtonContainer className={className} {...buttonProps}>
      <BorderSVG
        borderWidth={borderWidth}
        borderColor={borderColor}
        backgroundColor={backgroundColor}
        cutCornerSizePx={cutCornerSizePx}
      />
      {children}
    </SecondaryButtonContainer>
  );
};

const SecondaryButtonContainer = styled(DestyledButton)`
  min-width: 120px;
  padding: 0.5em 1.7em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 2px;
  background-color: transparent;
  color: ${(props) => props.theme.colors.highlight.color};
  position: relative;
  isolation: isolate;
  cursor: pointer;
  transition: opacity 200ms;
  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
`;

const BorderSVG: FC<
  React.PropsWithChildren<{
    borderWidth?: number;
    borderColor?: string;
    backgroundColor?: string;
    cutCornerSizePx?: number;
  }>
> = ({ borderWidth = 1, borderColor, backgroundColor, cutCornerSizePx = 8 }) => {
  const [ref, { width, height }] = useMeasure();
  const theme = useTheme();

  return (
    <SVGContainer ref={ref}>
      {!!width && !!height && (
        <svg width={width} height={height}>
          <polygon
            points={polygonPoints(
              borderWidth / 2,
              borderWidth / 2,
              width - borderWidth,
              height - borderWidth,
              cutCornerSizePx,
            )}
            strokeLinejoin="round"
            fill={backgroundColor ?? 'none'}
            stroke={borderColor ?? theme.colors.highlight.color}
            strokeWidth={borderWidth}
          />
        </svg>
      )}
    </SVGContainer>
  );
};

function polygonPoints(x: number, y: number, width: number, height: number, cut: number) {
  return `${x},${y} ${x + width - cut},${y} ${x + width},${y + cut} ${x + width},${y + height} ${x},${y + height}`;
}

const SVGContainer = styled.div`
  position: absolute;
  inset: 0;
  z-index: -1;
  > svg {
    overflow: visible;
  }
`;
