import { Language } from 'lib/api/apiTypes/LanguagesResponse';
import { Lesson } from 'lib/api/apiTypes/Lesson';
import { DashboardDataActions } from 'redux-lib/actions/dashboardData';
import { CampaignActions } from 'redux-lib/actions/campaign';
import { InitializationActions } from 'redux-lib/actions/initialization';
import { ParamsActions } from 'redux-lib/actions/params';
import { createReducer } from 'typesafe-actions';
import { CampaignResponse } from 'lib/api/apiTypes/CampaignResponse';
import { CertificateResponse } from 'lib/api/apiTypes/CertificateResponse';

export interface CampaignState {
  id: string | null;
  lessons: Lesson[];
  initialAssessment?: Lesson;
  finalAssessment?: Lesson;
  campaignResponse?: CampaignResponse;
  isReturningLearner?: boolean;
  certificateResponse?: CertificateResponse;
  languages: Language[];
  defaultLanguage: string;
  selectedLanguage?: string;
  languageChangeLoading?: boolean;
  error?: any;
  hasCompletedSpinToWin?: boolean;
}

const defaultState: CampaignState = {
  id: null,
  lessons: [],
  languages: [],
  defaultLanguage: 'en-GB',
};

export const campaignReducer = createReducer<
  CampaignState,
  DashboardDataActions | ParamsActions | InitializationActions | CampaignActions
>(defaultState)
  .handleAction(ParamsActions.setParams, (state, { payload }) =>
    payload.type === 'userDashboardInitializationParameters' ? state : { ...state, id: payload.campaignId },
  )
  .handleAction(CampaignActions.fetchResult, (state, { payload }) => {
    if (payload.type === 'Error') {
      return { ...state, error: payload.error };
    } else {
      return { ...state, campaignResponse: payload.value };
    }
  })
  .handleAction(CampaignActions.certificateFetchResult, (state, { payload }) => {
    return payload.type === 'OK' ? { ...state, certificateResponse: payload.value } : state;
  })
  .handleAction(DashboardDataActions.fetchResult, (state, { payload }) => {
    if (payload.type === 'Error') {
      return { ...state, error: payload.error };
    } else {
      const onlyLessons = payload.value.modules.filter(
        (m) => m.name !== 'Initial Assessment' && m.name !== 'Final Assessment',
      );
      const initialAssessment = payload.value.modules.find((m) => m.name === 'Initial Assessment');
      const finalAssessment = payload.value.modules.find((m) => m.name === 'Final Assessment');
      return {
        ...state,
        lessons: onlyLessons,
        initialAssessment,
        finalAssessment,
        defaultLanguage: payload.value.defaultLanguage,
      };
    }
  })
  .handleAction(InitializationActions.languagesReceived, (state, { payload }) => ({
    ...state,
    languages: payload.languages,
  }))
  .handleAction(CampaignActions.languageSelected, (state, { payload }) => ({
    ...state,
    selectedLanguage: payload,
    languageChangeLoading: true,
  }))
  .handleAction(CampaignActions.languageChangeSuccess, (state) => {
    return { ...state, languageChangeLoading: false };
  })
  .handleAction(CampaignActions.isReturningLearner, (state, { payload }) => ({
    ...state,
    isReturningLearner: payload,
  }))
  .handleAction(CampaignActions.spinToWinCampaignCompleted, (state) => ({
    ...state,
    hasCompletedSpinToWin: true,
  }));
