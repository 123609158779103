import { FontFaceDescriptor } from './lib/fonts/FontFaceDescriptor';
export const fontFaces: FontFaceDescriptor[] = [
  {
    family: 'MatterSQ',
    sources: [
      {
        src: '/fonts/MatterSQ-Light.woff',
        format: 'woff',
      },
    ],

    weight: '300',
    style: 'normal',
  },
  {
    family: 'MatterSQ',
    sources: [
      {
        src: '/fonts/MatterSQ-Regular.woff',
        format: 'woff',
      },
    ],

    weight: 'normal',
    style: 'normal',
  },
  {
    family: 'MatterSQ',
    sources: [
      {
        src: '/fonts/MatterSQ-SemiBold.woff',
        format: 'woff',
      },
    ],
    weight: '600',
    style: 'normal',
  },
  {
    family: 'Space Grotesk',
    sources: [
      {
        src: '/fonts/SpaceGrotesk-Bold.ttf',
        format: 'ttf',
      },
    ],
    weight: '700',
    style: 'normal',
  },
  {
    family: 'Poppins',
    sources: [
      {
        src: '/fonts/Poppins-Regular.ttf',
        format: 'ttf',
      },
    ],
    weight: 'normal',
    style: 'normal',
  },
  {
    family: 'Poppins',
    sources: [
      {
        src: '/fonts/Poppins-Medium.ttf',
        format: 'ttf',
      },
    ],
    weight: '500',
    style: 'normal',
  },
  {
    family: 'Poppins',
    sources: [
      {
        src: '/fonts/Poppins-SemiBold.ttf',
        format: 'ttf',
      },
    ],
    weight: '600',
    style: 'normal',
  },
  {
    family: 'Poppins',
    sources: [
      {
        src: '/fonts/Poppins-Bold.ttf',
        format: 'ttf',
      },
    ],
    weight: '700',
    style: 'normal',
  },
  {
    family: 'Poppins',
    sources: [
      {
        src: '/fonts/Poppins-ExtraBold.ttf',
        format: 'ttf',
      },
    ],
    weight: '800',
    style: 'normal',
  },
  {
    family: 'Poppins',
    sources: [
      {
        src: '/fonts/Poppins-Black.ttf',
        format: 'ttf',
      },
    ],
    weight: '900',
    style: 'normal',
  },
];
