import { cutCorner } from 'components/styled/css/cutCornerCss';
import { DestyledButton } from 'components/styled/DestyledButton';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
`;

export const Title = styled.h1`
  ${(props) => props.theme.fontFamilyCSS.heading}
  font-size: 20px;
  @media screen and (max-width: 550px) {
    font-size: 16px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 40px;
`;

export const Button = styled(DestyledButton)`
  min-width: 120px;
  padding: 0.5em 1.7em;
  background-color: ${(props) => props.theme.colors.highlight.color};
  color: #ffffff;
  border-radius: 2px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s;
  &:disabled {
    opacity: 0.5;
  }
  ${cutCorner('8px')}
`;
