import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    * {
        scroll-behavior: smooth;
    }

    html {
        box-sizing: border-box;
    }

    body {
        ${(props) => props.theme.fontFamilyCSS.normal}
        margin: 0;
        padding: 0;
        min-height: 100vh;
        width: 100%;
        box-sizing: border-box;
        color: ${(props) => props.theme.colors.text.color};
        background-color: ${(props) => props.theme.background.color};
    }

    *, *::before, *::after {
        box-sizing: inherit;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
    }
`;
