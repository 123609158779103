import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';

export const getAppInsightsTraceWrapper = (
  subject: string,
  appInsights: ApplicationInsights | undefined,
  additionalProperties?: Record<string, any>,
) =>
  appInsights
    ? {
        trace(message: string, data?: any, severityLevel: SeverityLevel = SeverityLevel.Information) {
          console.log(`ai trace ${severityLevel} (${subject}): ${message}`, data, additionalProperties);
          appInsights.trackTrace({
            message: subject,
            severityLevel,
            properties: { ...additionalProperties, timestamp: performance.now(), message, data },
          });
        },
      }
    : undefined;
