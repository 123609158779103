import styles from './error-styles.module.css';
import im from './images/im1.jpg';

export const CampaignExpired: React.FC<React.PropsWithChildren> = () => (
  <div className={styles.main}>
    <h1 className={styles.heading}>This training campaign has now expired</h1>
    <p className={styles.body}>
      The campaign you are trying to open has expired and locked. If you wish to complete this training, please contact
      your support team.
    </p>
    <img className={styles.image2} src={im} />
  </div>
);
