import React from 'react';
import { BrowserHistory, createBrowserHistory } from 'history';
import { BrowserHistoryContext } from '.';

export const BrowserHistoryProvider: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<{ history?: BrowserHistory }>>
> = ({ history: h, children }) => {
  const historyRef = React.useRef<BrowserHistory>();
  if (historyRef.current == null) {
    historyRef.current = h ?? createBrowserHistory({ window });
  }

  const history = historyRef.current;
  const value = React.useMemo(() => ({ history }), [history]);
  return <BrowserHistoryContext.Provider value={value}>{children}</BrowserHistoryContext.Provider>;
};
