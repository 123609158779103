import { useSelector } from 'react-redux';
import { RootState } from 'redux-lib/RootState';
import { CampaignAvailability } from '../../types/CampaignAvailability';
import { useCampaigns } from './useCampaigns';
import { useEnhancedCampaigns } from './useEnhancedCampaigns';

export function useDashboardData(availability: CampaignAvailability) {
  const dashboardData = useSelector((state: RootState) => state.userDashboard.dashboardData);
  const campaignsRaw = useCampaigns({ availability, dashboardData });
  const campaigns = useEnhancedCampaigns({ campaigns: campaignsRaw, dashboardData });
  return { dashboardData, campaigns };
}
