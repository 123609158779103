import { useCallback } from 'react';

export const useCopyToClipboard = (text: string): (() => Promise<void>) | undefined => {
  const { clipboardData } = window as any;
  const cb = useCallback(async () => {
    if (navigator.clipboard) {
      await navigator.clipboard?.writeText(text);
    } else {
      if (clipboardData) {
        clipboardData.setData('Text', text);
      }
    }
  }, [clipboardData, text]);
  return navigator.clipboard || clipboardData ? cb : undefined;
};
