import { getTenantIdentifier } from 'lib/axios/getTenantIdentifier';
export function addTenantIdentifier(urlString: string): string;
export function addTenantIdentifier(urlString: undefined): undefined;
export function addTenantIdentifier(urlString: string | undefined): string | undefined;
export function addTenantIdentifier(urlString: string | undefined): string | undefined {
  if (!urlString) {
    return undefined;
  }
  const url = new URL(urlString);
  const tenantIdentifier = getTenantIdentifier();
  if (tenantIdentifier) {
    url.searchParams.set('tenantIdentifier', tenantIdentifier);
  }
  return url.href;
}
