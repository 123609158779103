import { call, Effect, delay, CallEffect } from 'redux-saga/effects';
import { RetryInfo } from './types/RetryInfo';

type Retryable<T> = CallEffect<T> | ((...args: any[]) => Promise<T>);

const isCallEffect = <T>(obj: Retryable<T>): obj is CallEffect<T> => {
  const ceMaybe = obj as CallEffect<T>;
  return ceMaybe.combinator === false && ceMaybe['@@redux-saga/IO'] === true; // === so undefined is not coerced
};
export const defaultRetryOpts: Required<RetryOptions> = {
  initialDelay: 1875,
  delayMultiplier: 2,
  maximumDelay: 60000,
  maxAttempts: 3,
};
export const retryWithBackoff = <T>(effect: Retryable<T>, opts?: RetryWithBackoffOptions): CallEffect<T> =>
  call(function* (): Generator<Effect> {
    const { retryEffectFac, initialDelay, delayMultiplier, maximumDelay, maxAttempts } = {
      ...defaultRetryOpts,
      ...opts,
    };
    let currentDelay = initialDelay;
    let numAttempts = 0;
    for (;;) {
      try {
        if (isCallEffect(effect)) {
          return yield effect;
        } else {
          return yield call(effect);
        }
      } catch (err) {
        numAttempts++;
        if (maxAttempts > 0 && numAttempts >= maxAttempts) {
          throw err;
        } else {
          if (retryEffectFac != null) {
            const newErr: any = Error('Retry');
            newErr.innerError = err;
            yield retryEffectFac({ error: newErr, attempts: numAttempts, waitingForMS: currentDelay });
          }
        }
        yield delay(currentDelay);
        currentDelay = Math.min(currentDelay * delayMultiplier, maximumDelay);
      }
    }
  });

interface RetryOptions {
  initialDelay?: number;
  delayMultiplier?: number;
  maximumDelay?: number;
  maxAttempts?: number;
}

export interface RetryEffectFactoryOptions {
  retryEffectFac?: (result: RetryInfo) => Effect;
}
export type RetryWithBackoffOptions = RetryOptions & RetryEffectFactoryOptions;
