import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux-lib/RootState';
import { CircuitState } from 'redux-lib/reducers/networkStatus';

export type IndicatorState = 'ok' | 'minor-warning' | 'warning' | 'major-warning' | 'failure';

export type NetworkStatusInfo = {
  someFailed: boolean;
  someTimedOut: boolean;
  someRetrying: boolean;
  circuitBreakerState: CircuitState;
};

export function useIndicatorInfo(): {
  indicatorState: IndicatorState;
  networkStatusInfo: NetworkStatusInfo;
  color: string;
  flashRateMS?: number;
  message?: string;
  telemetryStatus: 'ok' | 'disabled' | 'retrying' | 'failed';
} {
  const telemetryStatus = useSelector((state: RootState) => state.telemetry.status);
  const networkStatusInfo = useNetworkStatusInfo();
  const indicatorState = React.useMemo(
    () => getIndicatorState(networkStatusInfo, telemetryStatus),
    [networkStatusInfo, telemetryStatus],
  );

  const info = indicatorInfo[indicatorState];
  return { ...info, indicatorState, networkStatusInfo, telemetryStatus };
}
const indicatorInfo: Record<IndicatorState, { color: string; flashRateMS?: number; message?: string }> = {
  ok: { color: 'limegreen' },
  failure: { color: 'red', message: 'A network request failed.' },
  'major-warning': {
    color: 'darkorange',
    flashRateMS: 2000,
    message: 'Several network requests have failed. Waiting a few seconds before retrying...',
  },
  warning: {
    color: 'orange',
    flashRateMS: 3000,
    message: 'Attempting to reconnect...',
  },
  'minor-warning': { color: '#aaaa00', flashRateMS: 4000, message: 'Connection lost. Retrying requests...' },
};

const useNetworkStatusInfo = (): NetworkStatusInfo => {
  const networkStatus = useSelector((state: RootState) => state.networkStatus);
  const s = React.useMemo(() => {
    const activePolicies = Object.values(networkStatus.policies);
    return {
      someFailed: activePolicies.some((p) => p.failed),
      someTimedOut: activePolicies.some((p) => p.timedOut),
      someRetrying: activePolicies.some((p) => p.retrying),
      circuitBreakerState: networkStatus.circuitBreakerState,
    };
  }, [networkStatus]);
  return s;
};

const getIndicatorState = (
  networkStatusInfo: NetworkStatusInfo,
  telemetryStatus: 'ok' | 'disabled' | 'retrying' | 'failed',
): IndicatorState => {
  if (networkStatusInfo.someFailed || telemetryStatus === 'failed') {
    return 'failure';
  }
  if (networkStatusInfo.circuitBreakerState === 'open' || networkStatusInfo.circuitBreakerState === 'isolated') {
    return 'major-warning';
  }
  if (networkStatusInfo.circuitBreakerState === 'halfOpen') {
    return 'warning';
  }
  if (networkStatusInfo.someRetrying || networkStatusInfo.someTimedOut || telemetryStatus === 'retrying') {
    return 'minor-warning';
  }
  return 'ok';
};
