import { useSelector } from 'react-redux';
import { RootState } from 'redux-lib/RootState';

export function useSelectedLanguageCode(): string {
  return useSelector(selectedLanguageCodeSelector);
}

export const selectedLanguageCodeSelector = (s: RootState): string => {
  return s.campaign.selectedLanguage || s.campaign.defaultLanguage || 'en-GB';
};
