import { Lesson } from 'lib/api/apiTypes/Lesson';
import React, { FC } from 'react';
import styled from 'styled-components';
import ModuleTile from './ModuleTile';

interface ModuleGridProps {
  lessons: Lesson[];
  showCompleted: boolean;
}

const ModuleGrid: FC<React.PropsWithChildren<ModuleGridProps>> = ({ lessons, showCompleted }) => {
  return (
    <GridContainer>
      {lessons
        .filter((l) => (showCompleted ? l.status === 'Completed' : l.status !== 'Completed'))
        .map((m, i) => (
          <ModuleTile moduleData={m} fullWidth={i === 0} idx={i} key={i} />
        ))}
    </GridContainer>
  );
};

const GridContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 625px) {
    margin-top: 5px;
  }
`;

export default ModuleGrid;
