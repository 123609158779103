import { SpinnerOverlay } from 'components/SpinnerOverlay';
import { TopBar } from 'components/TopBar';
import { NotFound } from 'components/error/error-screens/NotFound';
import Container from 'components/styled/Container';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux-lib/RootState';
import { spinToWinCampaignSelector } from 'redux-lib/selectors/spinToWinCampaignSelector';
import { SpinToWinWheel } from './SpinToWinWheel';
import { TelemetryActions } from 'redux-lib/actions/telemetry';
import { CampaignActions } from 'redux-lib/actions/campaign';
import { FC, useEffect, useState } from 'react';
import { useTexts } from 'lib/hooks/useTexts';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { DestyledButton } from 'components/styled/DestyledButton';
import Button from 'components/styled/Button';

export type SpinStatus = 'not-started' | 'started' | 'finished';

const SpinToWin = () => {
  const { campaignId, userId } = useParams();
  const [spinStatus, setSpinStatus] = useState<SpinStatus>('not-started');
  const dispatch = useDispatch();

  const languageChangeLoading = useSelector((state: RootState) => state.campaign.languageChangeLoading);

  const initialized = useSelector((state: RootState) => state.initialization.initialized);

  const spinToWinCampaignInfo = useSelector(spinToWinCampaignSelector);

  const [rewardLoseText, spinToWinSpinText, spinToWinHomeButtonText] = useTexts([
    'rewardLoseText',
    'spinToWinSpinText',
    'spinToWinHomeButtonText',
  ]);

  useEffect(() => {
    if (spinStatus === 'finished') {
      dispatch(
        TelemetryActions.send({
          type: 'CampaignLearnerRewarded',
        }),
      );

      dispatch(CampaignActions.spinToWinCampaignCompleted());
    }
  }, [dispatch, spinStatus]);

  if (!initialized) {
    return <SpinnerOverlay />;
  }

  if (!spinToWinCampaignInfo) {
    return <NotFound />;
  }

  const { isSpinToWinCampaign, isWinner, rewardDescription } = spinToWinCampaignInfo;

  if (!isSpinToWinCampaign) {
    return <NotFound />;
  }

  return (
    <PageContainer>
      {languageChangeLoading && <SpinnerOverlay />}
      <TopBar />
      <FullBackground>
        <FlexContainer>
          <SpinToWinWheel spinStatus={spinStatus} isWinner={isWinner} setSpinStatus={setSpinStatus} />
          {spinStatus === 'not-started' && (
            <PrimaryButton onClick={() => setSpinStatus('started')}>{spinToWinSpinText}</PrimaryButton>
          )}
          {spinStatus === 'finished' && (
            <RewardResult showResult={spinStatus === 'finished'}>
              {isWinner ? (
                <RewardWinnerInfo rewardDescription={rewardDescription}></RewardWinnerInfo>
              ) : (
                <RewardResultText>{rewardLoseText}</RewardResultText>
              )}
              <DasbhoardButton>
                <Button as={Link} to={`/dashboard/${campaignId}/${userId}`}>
                  {spinToWinHomeButtonText}
                </Button>
              </DasbhoardButton>
            </RewardResult>
          )}
        </FlexContainer>
      </FullBackground>
    </PageContainer>
  );
};

const RewardWinnerInfo: FC<{ rewardDescription?: string }> = ({ rewardDescription }) => {
  const [rewardWinHeadingText, rewardWinText, claimPrizeMessage] = useTexts([
    'rewardWinHeadingText',
    'rewardWinText',
    'claimPrizeMessage',
  ]);

  return (
    <>
      <RewardWinHeading>{rewardWinHeadingText}</RewardWinHeading>
      <RewardResultText>{rewardWinText}</RewardResultText>
      <RewardPrizeText>{rewardDescription}</RewardPrizeText>
      <RewardClaimText>{claimPrizeMessage}</RewardClaimText>
    </>
  );
};

export { SpinToWin };

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
`;

const FullBackground = styled.div`
  position: relative;
  background: #3abf871a;
  flex-grow: 1;
`;

const FlexContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
`;

const DasbhoardButton = styled.div`
  margin-top: 20px;
`;

const PrimaryButton = styled(DestyledButton)`
  position: absolute;
  top: calc(50% - 2.5rem);
  right: 2rem;
  height: 4.75rem;
  width: 15.75rem;
  border-radius: 0.8rem;
  cursor: pointer;
  background-color: #faff00;
  font-weight: 700;
  font-size: 1.2rem;
  text-align: center;

  @media screen and (max-width: 1100px) {
    position: initial;
    margin: 0 auto;
  }
`;

const RewardResult = styled.div<{ showResult: boolean }>`
  text-align: center;
  flex: ${(props) => (props.showResult ? 'none' : 0)};
  transition: flex 300ms ease;
`;

const RewardWinHeading = styled.h2`
  text-align: center;
`;

const RewardResultText = styled.span`
  text-align: center;
  font-size: max(2.2vmin, 1rem);
`;

const RewardPrizeText = styled.h1`
  text-align: center;
`;

const RewardClaimText = styled.p`
  text-align: center;
  font-size: max(2.2vmin, 1rem);
`;
