import { fontConfigToCSS, ThemeConfig } from 'outthink-frontend-training-theme';
import { DefaultTheme } from 'styled-components';

export function getBaseTheme({ colors, typefaces: fonts }: ThemeConfig, trainingTheme: ThemeConfig): DefaultTheme {
  const {
    primaryColor,
    secondaryColor,
    highlightColor,
    highlightColor2,
    highlightColor3,
    highlightColor4,
    successColor,
    successBgColor,
    warningColor,
    warningBgColor,
    dangerColor,
    dangerBgColor,
    infoColor,
    infoBgColor,
    dashboardTextColor,
    buttonIconColor,
    topBarColor,
  } = colors;
  return {
    fontFamilyCSS: {
      heading: fontConfigToCSS(fonts.heading),
      normal: fontConfigToCSS(fonts.normal),
      callToAction: fontConfigToCSS(fonts.callToAction),
    },
    colors: {
      primary: {
        color: primaryColor,
      },
      secondary: {
        color: secondaryColor,
      },
      highlight: {
        color: highlightColor,
      },
      highlight2: {
        color: highlightColor2,
      },
      highlight3: {
        color: highlightColor3,
      },
      highlight4: {
        color: highlightColor4,
      },
      success: {
        color: successColor,
      },
      successBg: {
        color: successBgColor,
      },
      warning: {
        color: warningColor,
      },
      warningBg: {
        color: warningBgColor,
      },
      danger: {
        color: dangerColor,
      },
      dangerBg: {
        color: dangerBgColor,
      },
      info: {
        color: infoColor,
      },
      infoBg: {
        color: infoBgColor,
      },
      text: {
        color: dashboardTextColor,
      },
    },
    background: {
      color: secondaryColor,
    },
    topBar: {
      color: topBarColor,
    },
    button: {
      backgroundColor: highlightColor2,
      color: secondaryColor,
      iconColor: buttonIconColor,
    },
    loginBackgroundGradient: {
      bottom: trainingTheme.colors.backgroundGradient.colorBottom,
      top: trainingTheme.colors.backgroundGradient.colorTop,
    },
  };
}
