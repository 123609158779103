export function getTenantIdentifier(): string | undefined {
  const qs = new URLSearchParams(window.location.search);
  const tenantIdentifier = qs.get('tenantIdentifier');
  if (tenantIdentifier) {
    return tenantIdentifier;
  }
  // this regex should match top-level routing
  // declared in src/router/Router.tsx
  const regExpArr = /^(?:\/([^/]*))?\/(user-)?dashboard\//.exec(window.location.pathname);
  // if (!regExpArr) {
  //     throw Error(
  //         'could not match location correctly to extract first segment. Please check that top-level routing and the regexp at this site match',
  //     );
  // }
  return regExpArr?.[1] ?? undefined;
}
